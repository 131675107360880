import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  beneficiaries: [],
  isLoading: true,
};

function createData(
  sn,
  id,
  account_name,
  account_number,
  bank_name,
  bank_code
) {
  return { sn, id, account_name, account_number, bank_name, bank_code };
}

export const getBeneficiaries = createAsyncThunk(
  "beneficiaries/getBeneficiaries",
  async (platformWalletID, thunkAPI) => {
    try {
      let url = `/dvault/__beneficiary?wallet_id=${platformWalletID}`;
      const res = await customFetch.get(url);
      const newData = res?.data?.map((dataItem, index) => {
        const { id, account_name, account_number, bank_name, bank_code } =
          dataItem;

        return createData(
          index + 1,
          id,
          account_name,
          account_number,
          bank_name,
          bank_code
        );
      });

      return newData;
    } catch (error) {
      thunkAPI.rejectWithValue("Ooopppsss! Something went wrong");
    }
  }
);

const createURL = (platformWalletID) =>
  `/dvault/__create-beneficiary?wallet_id=${platformWalletID}`;

const editURL = (platformWalletID, id) =>
  `wallets/v1/${platformWalletID}/beneficiary/${id}/`;

export const addBeneficiary = createAsyncThunk(
  "beneficiaries/addBeneficiary",
  async ({ platformWalletID, beneficiaryData }, thunkAPI) => {
    try {
      const url = createURL(platformWalletID);

      const res = await customFetch.post(url, beneficiaryData);
      const newBeneficiary = res?.data;
      thunkAPI.dispatch(updateBeneficiaries(newBeneficiary));
      toast.success("Beneficiary added successfully");
      return newBeneficiary;
    } catch (error) {
      thunkAPI.rejectWithValue(
        "Oops! Something went wrong while adding a beneficiary"
      );
      toast.error(
        error.response.data.error
          ? error.response.data.error.toString()
          : "Failed to add beneficiary"
      );
    }
  }
);

export const deleteBeneficiaryAsync = createAsyncThunk(
  "beneficiaries/deleteBeneficiary",
  async ({ platformWalletID, beneficiaryId }, thunkAPI) => {
    try {
      const url = `/dvault/__delete-beneficiary?beneficiary_id=${beneficiaryId}&wallet_id=${platformWalletID}`;

      await customFetch.delete(url);
      toast.success("beneficiery deleted succesfully");
      return beneficiaryId;
    } catch (error) {
      toast.error("Failed to delete beneficiary");
      thunkAPI.rejectWithValue("Failed to delete beneficiary");
    }
  }
);

const beneficiariesSlice = createSlice({
  name: "beneficiaries",
  initialState,
  reducers: {
    updateBeneficiaries(state, action) {
      state.beneficiaries.push(action.payload);
    },

    deleteBeneficiary: (state, action) => {
      const deletedItemId = action.payload;
      state.beneficiaries = state.beneficiaries.filter(
        (beneficiary) => beneficiary.id !== deletedItemId
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBeneficiaries.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBeneficiaries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.beneficiaries = action.payload;
    });
    builder.addCase(getBeneficiaries.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addBeneficiary.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addBeneficiary.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(addBeneficiary.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteBeneficiaryAsync.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteBeneficiaryAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      const deletedItemId = action.payload;
      state.beneficiaries = state.beneficiaries.filter(
        (beneficiary) => beneficiary.id !== deletedItemId
      );
    });
    builder.addCase(deleteBeneficiaryAsync.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { updateBeneficiaryAfterDelete, updateBeneficiaries, isLoading } =
  beneficiariesSlice.actions;

export default beneficiariesSlice.reducer;
