import React from "react";
import { Grid } from "@mui/material";
import {
  TerminalIndicator,
  BranchIndicator,
  TotalBalanceIndicator,
  TotalTransactionIndicator,
} from "../components/IndicatorCard";


function Indicators() {
  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        columnSpacing="20px"
        sx={{
          marginTop: "2rem",
        }}
      >
        <Grid item md={4} lg={4}>
          <TotalBalanceIndicator />
        </Grid>
        <Grid item md={4} lg={4}>
          <BranchIndicator />
        </Grid>
        <Grid item md={4} lg={4}>
          <TerminalIndicator />
        </Grid>
      </Grid>
      <TotalTransactionIndicator />
    </>
  );
}

export default Indicators;
