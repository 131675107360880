import * as React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "17.2494px",
};

export const BeneficiaryModal = ({
  OpenModal,
  handleClose,
  modalTitle,
  children,
}) => {
  return (
    <div>
      <Modal
        open={OpenModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "none" }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 400,
              fontSize: "20px",
            }}
          >
            <Box
              id="modal-modal-title"
              component="div"
              sx={{
                textAlign: "start",
                color: "#000",
                marginBottom: "10px",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              {modalTitle}
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Modal>
    </div>
  );
};
