import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Stack,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import customFetch from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { changePageNumber, logoutUser } from "../features/dvaultUserSlice";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DialogBox from "./form/DialogBox";
import ReassignTerminalForm from "./ReassignTerminalForm";
import ReusableTransTable from "./ReusableTransTable";
import CancelIcon from "@mui/icons-material/Cancel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { dateConverter2 } from "../utils/functionUtil";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const columns = [
  { id: "sn", label: "SN", minWidth: 70, align: "left" },
  { id: "account_name", label: "Terminal name", minWidth: 170, align: "left" },
  {
    id: "serial_number",
    label: "Serial Number",
    minWidth: 170,
    align: "left",
  },
  {
    id: "perform_action",
    label: "Perform action",
    minWidth: 170,
    align: "left",
  },
  { id: "eye", label: "", minWidth: 70, align: "left" },
];

function createData(sn, id, account_name, serial_number, branch_name) {
  return {
    sn,
    id,
    account_name,
    serial_number,
    branch_name,
  };
}
export const TerminalListTable = ({ isLoading, setIsLoading }) => {
  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;
  const dispatch = useDispatch();
  const { pageNumber } = useSelector((store) => store.dvaultUser);
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageNumber);
  const [allKYC, setAllKYC] = useState([]);
  const currentID = useRef("");
  const [localSearch] = useState("");
  const [anchorEl, setAnchorEL] = useState(null);
  const [currentTID, setCurrentTId] = useState("");
  const [assignedAgent, setAssignedAgent] = useState("");
  const [idCardIsVerified] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [showTable, setShowTable] = useState({});

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    setPage(0);
    dispatch(changePageNumber(0));

    if (ranges.selection.startDate && ranges.selection.endDate) {
    } else if (ranges.selection.startDate && !ranges.selection.endDate) {
      setDateRangeAnchorEl(dateRangeAnchorEl);
    }
  };

  const handleClick = (event, TID, accountName) => {
    setAnchorEL(event.currentTarget);
    setCurrentTId(TID);
    setAssignedAgent(accountName);
  };
  const handleClose = () => {
    setAnchorEL(null);
  };
  const handleDateRangeClick = (event) => {
    setDateRangeAnchorEl(event.currentTarget);
  };
  const handleDateRangeClose = () => {
    setDateRangeAnchorEl(null);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    setDateRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
  };
  const { pid } = useParams();

  let url = `/dvault/__pos_terminal_list?platform_id=${platformID}`;

  const open = Boolean(anchorEl);

  const getAllKYC = async () => {
    setIsLoading(true);
    try {
      if (dateRange.startDate && dateRange.endDate) {
        url =
          url +
          `&?created_at=${dateConverter2(dateRange.startDate)},${dateConverter2(
            dateRange.endDate
          )}`;
      }
      const res = await customFetch.get(url);
      const newData = res.data.results.map((dataItem, index) => {
        const { id, serial_number } = dataItem;

        let accountName = dataItem.wallet?.account_name;
        return createData(
          index + 1,
          id,
          accountName || "No name",
          serial_number || "serial"
        );
      });
      setAllKYC(newData);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
        navigate("/login");
        return toast.error("Unauthorized! Logging Out...");
      } else if (error.response) {
        toast.error("An error occured");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // pagination

  const itemsPerPage = 20;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = allKYC.slice(startIndex, endIndex);
  const totalItems = allKYC.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const renderPaginationRange = () => {
    const startRange = (currentPage - 1) * itemsPerPage + 1;
    const endRange = Math.min(currentPage * itemsPerPage, totalItems);
    return `${startRange}-${endRange} of ${totalItems}`;
  };
  // pagination

  useEffect(() => {
    const debounceId = setTimeout(() => {
      getAllKYC();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [page, localSearch, idCardIsVerified, dateRange]);

  let buttonLabel =
    dateRange.startDate && dateRange.endDate
      ? `${dateRange.startDate.toDateString()} - ${dateRange.endDate.toDateString()}`
      : "Choose Date Range";
  let today = new Date();
  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Box
        sx={{
          width: "100%",
          marginBottom: "30px",
        }}
      >
        <Paper elevation={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={4}
            py={1.8}
          >
            {/* date range */}
            <Box>
              <Button
                variant="outlined"
                onClick={handleDateRangeClick}
                startIcon={<CalendarMonthIcon />}
                endIcon={<CancelIcon onClick={handleCancelClick} />}
                color={"secondary"}
              >
                {buttonLabel}
              </Button>

              <Popover
                open={Boolean(dateRangeAnchorEl)}
                anchorEl={dateRangeAnchorEl}
                onClose={handleDateRangeClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <DateRangePicker
                  ranges={[dateRange]}
                  onChange={handleSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  direction="horizontal"
                  months={2}
                  maxDate={today}
                  showPreview={true}
                />
              </Popover>
            </Box>
          </Stack>
        </Paper>
      </Box>
      <Box
        sx={{
          marginTop: "27px",
          marginBottom: "10px",
        }}
      ></Box>
      <Box
        sx={{
          marginTop: "10px",
        }}
      >
        {paginatedData && (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#EFF1F9",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          onMouseOver={() => {
                            currentID.current = `${row.id}`;
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "eye" ? (
                                  <IconButton
                                    onClick={() =>
                                      setShowTable({
                                        ...showTable,
                                        [row.serial_number]:
                                          !showTable[row.serial_number],
                                      })
                                    }
                                  >
                                    {showTable[row.serial_number] ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                ) : column.id === "perform_action" ? (
                                  <Box>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      endIcon={<UnfoldLessIcon />}
                                      aria-describedby={pid}
                                      onClick={(e) =>
                                        handleClick(e, row.id, row.account_name)
                                      }
                                      sx={{ color: "#9199B1" }}
                                    >
                                      perform action
                                    </Button>
                                    <Popover
                                      id={pid}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "140px",
                                          padding: "0.5rem 0.7rem",
                                          cursor: "pointer",
                                          textAlign: "center",
                                        }}
                                      >
                                        <DialogBox
                                          buttonText="Reassign terminal"
                                          buttonColor="secondary"
                                          title="Reassign terminal"
                                          variant="text"
                                        >
                                          {({ handleClose }) => {
                                            return (
                                              <ReassignTerminalForm
                                                handleClose={handleClose}
                                                tid={currentTID}
                                                assigned={assignedAgent}
                                              />
                                            );
                                          }}
                                        </DialogBox>
                                      </Box>
                                    </Popover>
                                  </Box>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {showTable[row.serial_number] && (
                          <TableRow>
                            <TableCell colSpan={columns.length}>
                              <ReusableTransTable
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                terminal_id={row.id}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                margin: "20px auto",
              }}
            >
              <Box style={{ margin: "0 10px" }}>{renderPaginationRange()}</Box>
              <IconButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
