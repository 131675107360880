import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currencyConverter } from "../utils/functionUtil";

function TransactHistoryTable({
  updatedAt,
  isVerified,
  kycID,
  sender,
  charges,
  charges_amount,
  amount,
  type,
  paymentMethod,
  referenceID,
  currency,
  status,
  description,
}) {
  const theme = useTheme();
  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;

  const { isLoading } = useSelector((store) => store.dvaultUser);

  const updateRequestUrl = `/transactions/${kycID}/`;
  const [openStatus, setOpenStatus] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "status-popover" : undefined;

  const handleStatusClickClose = () => {
    setOpenStatus(false);
  };

  const convertDate = () => {
    if (updatedAt) {
      const dateObject = parseISO(updatedAt);
      const formattedData = format(dateObject, "yyyy-MM-dd");
      return formattedData;
    }
  };

  useEffect(() => {
    if (updatedAt) {
      convertDate();
    }
  }, [updatedAt]);
  return (
    <Paper
      sx={{
        padding: "30px",
        marginTop: "50px",
      }}
    >
      <Stack direction="row" alignItems={"center"} spacing={1}>
        {status === "SUCCESS" ? (
          <Chip
            label="Success"
            sx={{
              "&.MuiChip-root": {
                background: `${theme.palette.common.green}`,
                color: `${theme.palette.common.greenIcon}`,
                fontWeight: 500,
              },
            }}
          />
        ) : status === "FAILED" ? (
          <Chip
            label="Failed"
            sx={{
              "&.MuiChip-root": {
                background: `${theme.palette.common.flagRed}`,
                color: `${theme.palette.common.purpleIcon}`,
                fontWeight: 500,
              },
            }}
          />
        ) : (
          <Chip
            label="Initialized"
            sx={{
              "&.MuiChip-root": {
                background: `${theme.palette.common.purple}`,
                color: `${theme.palette.common.flagIcon}`,
                fontWeight: 500,
              },
            }}
          />
        )}
      </Stack>
      <Grid
        container
        justifyContent={"space-between"}
        columnSpacing={1}
        sx={{
          my: 5,
        }}
      >
        <Grid item>
          <Box
            sx={{
              maxWidth: "400px",
            }}
          ></Box>
        </Grid>
        <Grid item>
          <Stack>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Type
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {type}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Payment Method
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {paymentMethod}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Reference ID
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {referenceID}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Updated at
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {convertDate()}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <Stack>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Sender
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {(sender = "Null")}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Amount
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {currencyConverter(amount)}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Currency
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                  maxWidth: "200px",
                }}
              >
                {currency}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <Stack>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Description
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {description}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Charges Amount
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {charges_amount}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Charges
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {charges}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          minWidth: 120,
          display: "block",
          marginLeft: "auto",
          width: 200,
        }}
      >
        <FormControl fullWidth>
          <Dialog
            open={openStatus}
            onClose={handleStatusClickClose}
            sx={{
              "&.MuiDialog-root": {},
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
              },
            }}
          >
            <Paper
              sx={{
                width: "300px",
                p: 3,
              }}
            >
              <Box
                sx={{
                  marginTop: "15px",
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    "&.MuiButton-root": {
                      width: "48%",
                      color: "rgba(21, 25, 32, 0.5)",
                      borderRadius: "10px",
                      fontWeight: 500,
                      texTransform: "capitalize",
                    },
                  }}
                  onClick={() => {
                    handleStatusClickClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    "&.MuiButton-root": {
                      width: "48%",
                      marginLeft: "10px",
                      borderRadius: "10px",
                      fontWeight: 500,
                      textTransform: "capitaize",
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={35}
                      sx={{
                        color: "common.white",
                      }}
                    />
                  ) : isVerified ? (
                    "Unverified"
                  ) : (
                    "Verified"
                  )}
                </Button>
              </Box>
            </Paper>
          </Dialog>
        </FormControl>
      </Box>
    </Paper>
  );
}

export default TransactHistoryTable;
