import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";

import dukkkalogo1 from "../assets/dukkalogo1.png";
import Alert from "@mui/material/Alert";
import BeneficiariesIcon from "../assets/beneficiaries.svg";
import BeneficiariesActiveIcon from "../assets/beneficiariesActive.svg";
import ProfileActive from "../assets/profile.svg";
import ProfileInactive from "../assets/profileInactive.svg";
import { logoutUser } from "../features/dvaultUserSlice";
import ResetPasswordForm from "../components/ResetPasswordForm";

function SharedLayout() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [dvaultUser] = useState(JSON.parse(localStorage.getItem("dvaultUser")));
  const selectedPlatform = JSON.parse(localStorage.getItem("selectedPlatform"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [openResetForm, setOpenResetForm] = React.useState(true);



  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (dvaultUser) {
      setTimeout(() => {
        setEmail(dvaultUser.email);
        setFullName(`${dvaultUser.firstName} ${dvaultUser.lastName}`);
      }, [750]);
    }
  }, [dvaultUser]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPop = open ? "simple-popover" : undefined;
  let pageTitle;

  if (location.pathname === `/business-wallet/${id}`) {
    pageTitle = "Dashboard";
  } else if (location.pathname === "/business-wallet") {
    pageTitle = "Business Wallet";
  } else if (location.pathname === "/beneficiaries") {
    pageTitle = "Beneficiaries";
  } else {
    pageTitle = "Dashboard";
  }

  return (
    <>
      {matches && (
        <h3 style={{ textAlign: "center" }}>
          content only available on Desktop mode
        </h3>
      )}
      <Grid
        container
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <Grid
          item
          xs={2.5}
          sx={{
            height: "100vh",
            position: "sticky",
            top: 0,
          }}
        >
          <Box sx={{ px: 2 }}>
            <Stack>
              <Box
                component="img"
                alt="Dvault"
                src={dukkkalogo1}
                sx={{
                  width: "180px",
                  margin: "0 auto",
                }}
              />
            </Stack>

            <Box>
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  sx={{
                    background:
                      location.pathname === "/" ||
                      location.pathname === `/balance-details/${id}`
                        ? `${theme.palette.common.linkRedBg}`
                        : "none",
                    my: 2,
                    marginTop: "60px",

                    padding: "10px",
                    paddingLeft: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid item xs={3}>
                    <Box
                      component={"img"}
                      src={
                        location.pathname === "/" ||
                        location.pathname === `/balance-details/${id}`
                          ? ProfileActive
                          : ProfileInactive
                      }
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "24px",
                        fontWeight: 600,
                        color:
                          location.pathname === "/" ||
                          location.pathname === `/balance-details/${id}`
                            ? `${theme.palette.primary.main}`
                            : "#8CA1B6",
                      }}
                    >
                      Dashboard
                    </Typography>
                  </Grid>
                </Grid>
              </NavLink>
              {
                selectedPlatform &&
              <NavLink
                to="/beneficiaries"
                style={{
                  textDecoration: "none",
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  sx={{
                    my: 2,
                    padding: "10px",
                    paddingLeft: "10px",
                    borderRadius: "10px",
                    background:
                      location.pathname === "/beneficiaries"
                        ? `${theme.palette.common.linkRedBg}`
                        : "none",
                  }}
                >
                  <Grid item xs={3}>
                    <Box
                      component={"img"}
                      src={
                        location.pathname === "/beneficiaries"
                          ? BeneficiariesActiveIcon
                          : BeneficiariesIcon
                      }
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "24px",
                        fontWeight: 600,
                        color:
                          location.pathname === "/beneficiaries"
                            ? `${theme.palette.primary.main}`
                            : "#8CA1B6",
                      }}
                    >
                      Beneficiaries
                    </Typography>
                  </Grid>
                </Grid>
              </NavLink>
             }
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={9.5}
          sx={{
            background: "#F3F3F3;",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              px: 6,
              py: 1,
              background: "#FFFFFF",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontSize: "2.1rem",
                fontWeight: 500,
              }}
            >
              {pageTitle}
            </Typography>
            <AccountCircleOutlinedIcon
              sx={{
                fontSize: "2.5rem",
              }}
              onClick={handleClick}
            />
            <Popover
              id={idPop}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  "&.MuiPaper-root": {
                    width: "250px",
                    p: 2,
                  },
                }}
              >
                <Stack>
                  <Box>
                    <AccountCircleOutlinedIcon
                      sx={{
                        fontSize: "2.5rem",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "1.3rem",
                        my: 2,
                      }}
                    >
                      {fullName}
                    </Typography>
                    <Divider />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "500",
                        my: 1,
                      }}
                    >
                      {email}
                    </Typography>
                    <Divider />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "500",
                        my: 1,
                        color: `${theme.palette.common.flagIcon}`,
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => dispatch(logoutUser())}
                    >
                      Sign out
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Popover>
          </Stack>
          <Box
            sx={{
              px: 6,
            }}
          >
            {!dvaultUser?.resetPassword ? (
              <Box>
                <Dialog
                  open={openResetForm}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <Alert severity="warning">
                    Welcome! For security reasons, please reset your password to
                    continue.
                  </Alert>
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{ textAlign: "center" }}
                  >
                    Reset Your Password
                  </DialogTitle>
                  <DialogContent>
                    <ResetPasswordForm />
                  </DialogContent>
                </Dialog>
              </Box>
            ) : (
              <Outlet />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default SharedLayout;
