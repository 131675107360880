import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  InputAdornment,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { changePageNumber } from "../features/dvaultUserSlice";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const columns = [
  { id: "sn", label: "S/N", minWidth: 70, align: "left" },

  // { id: "id", label: "ID", minWidth: 70, align: "left" },
  { id: "account_name", label: "Accout Name", minWidth: 170, align: "left" },
  {
    id: "account_number",
    label: "Account Number",
    minWidth: 170,
    align: "center",
  },

  { id: "balance", label: "Branch Balance", minWidth: 170, align: "left" },
  { id: "eye", label: "", minWidth: 70, align: "left" },
];

export const BusinessWalletTable = ({ isLoading, onSubmitSuccess }) => {
  const dispatch = useDispatch();

  const businessBranch = useSelector(
    (store) => store.businessBranch?.businessBranch
  );
  const { pageNumber } = useSelector((store) => store.dvaultUser);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageNumber);

  const currentID = useRef("");
  const [anchorEl, setAnchorEL] = useState(null);
  const [idCardIsVerified, setIdCardIsVerified] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const handleLocalSearchChange = (e) => {
    // setLocalSearch(e.target.value); //hidden
    setPage(0);
    dispatch(changePageNumber(0));
  };



  // pagination

  const itemsPerPage = 100;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = businessBranch?.slice(startIndex, endIndex);
  const totalItems = businessBranch?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const renderPaginationRange = () => {
    const startRange = (currentPage - 1) * itemsPerPage + 1;
    const endRange = Math.min(currentPage * itemsPerPage, totalItems);
    return `${startRange}-${endRange} of ${totalItems}`;
  };
  // pagination

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  useEffect(() => {
    const debounceId = setTimeout(() => {
    }, 750);
    return () => clearTimeout(debounceId);
  }, [page, idCardIsVerified, dateRange]);

  let today = new Date();
  return (
    <>
    
      <Box
        sx={{
       
          margin: " 4rem 0 2.5rem",
        }}
      >
        <Paper elevation={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={4}
            py={1.8}
          >
            {/* search */}
            <Box sx={{ margin: "0 2rem" }}>
              <TextField
                id="localSearch"
                color="accent"
                // value={localSearch} hidden
                onChange={handleLocalSearchChange}
                sx={{
                  "& .MuiTextField-root": {
                    width: 200,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "39px",
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.4rem",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  placeholder: "Search by name",
                }}
              />
            </Box>
           
          </Stack>
        </Paper>
      </Box>
      <Box
        sx={{
          marginTop: "10px",
        }}
      >
        {paginatedData && (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#EFF1F9",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover
                        onMouseOver={() => {
                          currentID.current = `${row.id}`;
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "eye" ? (
                                <IconButton
                                  onClick={() =>
                                    navigate(`/business-wallet/${row.id}`)
                                  }
                                >
                                  <Visibility />
                                </IconButton>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination component */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                margin: "20px auto",
              }}
            >
           
              <Box sx={{ margin: "0 10px" }}>{renderPaginationRange()}</Box>
              <IconButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
