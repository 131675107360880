import { Backdrop, CircularProgress, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import customFetch from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, makeIsLoadingFalse } from "../features/dvaultUserSlice";
import { WalletHistoryTable } from "../components/WalletHistoryTable";
import { TerminalList } from "../components/TerminalList";
import { TransactionHistoryDetails } from "../components/TransactionHistoryDetails";

export const BusinessWalletDetails = () => {
  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => store.dvaultUser);
  const [updatedDetails, setUpdatedDetails] = useState({});
  const [KYCDetails, setKYCDetails] = useState({
    id: "",
    account_name: "",
    account_number: "",
    balance: "",
    status: "",
    paymentMethod: "",
    type: "",
    description: "",
    reference_id: "",
    wallet: "",
    sender: "",
    charges: "",
    charges_amount: "",
    amount: "",
    currency: "",
    isLoading: true,
  });

  const { pid } = useParams();

  const getKycDetails = async () => {
    if (!platformID) return;
    try {
      let url = `/dvault/__wallet_detail?platform_id=${platformID}&id=${pid}`;
   

      const res = await customFetch.get(url);
      const {
        id,
        account_name,
        account_number,
        balance,
        created_at,
        updated_at,
        currency,
        kyc: { phone_number, email, state, address, country },
      } = res.data;

      setKYCDetails({
        id,
        account_name,
        account_number,
        balance,
        created_at,
        updated_at,
        currency,
        phone_number,
        email,
        state,
        address,
        country,
      });

      dispatch(makeIsLoadingFalse());
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
        navigate("/login");
        return toast.error("Unauthorized! Logging Out...");
      }
      toast.error("An error occurred");
      dispatch(makeIsLoadingFalse());
    }
  };

  useEffect(() => {
    getKycDetails();
       // eslint-disable-next-line
  }, [platformID]);


  if (isLoading) {
    return (
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }
  return (
    <>
       <Typography
        sx={{
          fontWeight: "500",
          fontSize: "2.0rem",
          // margin: "2.5rem 0",
          cursor: "pointer"
        }}
        onClick={()=>navigate("/dashboard")}
      >
        &lt; back
      </Typography>
      <Box sx={{ margin: "2.5rem 0 2.5rem" }}>
        <Typography
          sx={{
            fontWeight: "500", fontSize: "1.5rem",
            margin: "2.5rem 0"
          }}
        >
          Branch Details
        </Typography>
        <WalletHistoryTable
          statusProp="verified"
          {...KYCDetails}
          setUpdatedDetails={setUpdatedDetails}
          platformID={KYCDetails.platformID}
          kycID={KYCDetails.id}
          isLoading={isLoading}
        />
      </Box>

      <TerminalList />
      <TransactionHistoryDetails KYCDetails={KYCDetails} wallet_id={pid} />
    </>
  );
};
