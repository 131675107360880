import { Box, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React from "react";
import { TextError } from "./TextError";
function Input({ label, name, ...rest }) {
  return (
    <Box >
      <Field name={name}>
        {({ field, form }) => (
          <>
            <Box
              component="label"
              htmlFor={name}
              sx={{
                display: "block",
                py: 1,
                fontSize: "1rem",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontWeight: 400,
                }}
              >
                {label}
              </Typography>
            </Box>
            <TextField
              error={form.errors[name] && form.touched[name]}
              id={name}
              {...rest}
              {...field}
              fullWidth
              sx={{
                "&.MuiTextField-root": {
                  background: "rgba(0, 0, 0, 0.04)",
                },
              }}
            />
            <ErrorMessage component={TextError} name={name} />
          </>
        )}
      </Field>
    </Box>
  );
}

export default Input;
