import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePageNumber } from "../features/dvaultUserSlice";
import { DateRangePicker } from "react-date-range";
import DialogBox from "./form/DialogBox";
import ReassignTerminalForm from "./ReassignTerminalForm";
import ReusableTransTable from "./ReusableTransTable";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const columns = [
  { id: "sn", label: "SN", minWidth: 70, align: "left" },
  { id: "account_name", label: "Terminal name", minWidth: 170, align: "left" },

  {
    id: "serial_number",
    label: "Serial Number",
    minWidth: 170,
    align: "left",
  },
  {
    id: "perform_action",
    label: "Perform action",
    minWidth: 170,
    align: "left",
  },
];

function createData(sn, id, account_name, serial_number) {
  return {
    sn,
    id,
    account_name,
    serial_number,
  };
}

export const AllTerminalListTable = () => {
  const dispatch = useDispatch();
  const { pageNumber } = useSelector((store) => store.dvaultUser);
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = useState(null);
  const [page, setPage] = useState(pageNumber);
  const currentID = useRef("");
  const [anchorEl, setAnchorEL] = useState(null);
  const [currentTID, setCurrentTId] = useState("");
  const [assignedAgent, setAssignedAgent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [showTable, setShowTable] = useState({});

  const terminals = useSelector((store) => store.terminals.terminals);
  const isLoading = useSelector((state) => state.terminals.isLoading);

  // pagination

  const itemsPerPage = 100;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = terminals.slice(startIndex, endIndex);
  const totalItems = terminals.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const renderPaginationRange = () => {
    const startRange = (currentPage - 1) * itemsPerPage + 1;
    const endRange = Math.min(currentPage * itemsPerPage, totalItems);
    return `${startRange}-${endRange} of ${totalItems}`;
  };
  // pagination

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    setPage(0);
    dispatch(changePageNumber(0));

    if (ranges.selection.startDate && ranges.selection.endDate) {
      // setDateRangeAnchorEl(null);
    } else if (ranges.selection.startDate && !ranges.selection.endDate) {
      setDateRangeAnchorEl(dateRangeAnchorEl);
    }
  };

  const handleClick = (event, TID, accountName) => {
    setAnchorEL(event.currentTarget);
    setCurrentTId(TID);
    setAssignedAgent(accountName);
  };
  const handleClose = () => {
    setAnchorEL(null);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    setDateRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
  };
  const { pid } = useParams();

  const open = Boolean(anchorEl);

  let today = new Date();
  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Box
        sx={{
          marginTop: "27px",
          marginBottom: "10px",
        }}
      >
        <Popover
          open={Boolean(dateRangeAnchorEl)}
          anchorEl={dateRangeAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <DateRangePicker
            ranges={[dateRange]}
            onChange={handleSelect}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            direction="horizontal"
            months={2}
            maxDate={today}
            showPreview={true}
          />
        </Popover>
      </Box>
      <Box
        sx={{
          marginTop: "10px",
        }}
      >
        {paginatedData && (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#EFF1F9",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          onMouseOver={() => {
                            currentID.current = `${row.id}`;
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                        
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "eye" ? (
                                  <IconButton
                                    onClick={() =>
                                      setShowTable({
                                        ...showTable,
                                        [row.serial_number]:
                                          !showTable[row.serial_number],
                                      })
                                    }
                                  >
                                    {showTable[row.serial_number] ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                ) : column.id === "perform_action" ? (
                                  <Box>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      endIcon={<UnfoldLessIcon />}
                                      aria-describedby={pid}
                                      onClick={(e) =>
                                        handleClick(e, row.id, row.account_name)
                                      }
                                      sx={{ color: "#9199B1" }}
                                    >
                                      perform action
                                    </Button>
                                    <Popover
                                      id={pid}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "140px",
                                          padding: "0.5rem 0.7rem",
                                          cursor: "pointer",
                                          textAlign: "center",
                                        }}
                                      >
                                        <DialogBox
                                          buttonText="Reassign terminal"
                                          buttonColor="secondary"
                                          title="Reassign terminal"
                                          variant="text"
                                        >
                                          {({ handleClose }) => (
                                            <ReassignTerminalForm
                                              handleClose={handleClose}
                                              tid={currentTID}
                                              assigned={assignedAgent}
                                            />
                                          )}
                                        </DialogBox>
                                      </Box>
                                    </Popover>
                                  </Box>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {showTable[row.serial_number] && (
                          <TableRow>
                            <TableCell colSpan={columns.length}>
                              <ReusableTransTable
                                isLoading={isLoading}
                                terminal_id={row.id}
                           
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination component */}
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                margin: "20px auto",
              }}
            >
              <div style={{ margin: "0 10px" }}>{renderPaginationRange()}</div>
              <IconButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <ChevronRight />
              </IconButton>
            </div>
          </>
        )}
      </Box>
    </>
  );
};
