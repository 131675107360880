import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import customFetch from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, makeIsLoadingFalse } from "../features/dvaultUserSlice";
import TransactHistoryTable from "../components/TransactHistoryTable";

function TransactionHistory() {
  const navigate = useNavigate();
  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => store.dvaultUser);
  const [status, setStatus] = useState("");
  const [updatedDetails, setUpdatedDetails] = useState({});
  const [KYCDetails, setKYCDetails] = useState({
    status: "",
    paymentMethod: "",
    type: "",
    description: "",
    reference_id: "",
    wallet: "",
    sender: "",
    charges: "",
    charges_amount: "",
    amount: "",
    currency: "",
    isLoading: true,
  });

  const { id } = useParams();
  let url = `/transactions/v1/${platformID}/transactions/${id}/`;

  const getKycDetails = async () => {
    try {
      const resp = await customFetch.get(url);
      const {
        id,
        status,
        payment_method,
        type,
        description,
        reference_id,
        wallet,
        sender,
        charges,
        charges_amount,
        amount,
        updated_at,
        currency,
        created_at,
      } = resp.data;

      setKYCDetails({
        createdAt: created_at,
        isLoading: false,
        updatedAt: updated_at,
        id: id,
        status: status,
        paymentMethod: payment_method,
        type: type,
        description: description,
        referenceID: reference_id,
        wallet: wallet,
        sender: sender,
        charges: charges,
        charges_amount: charges_amount,
        amount: amount,
        currency: currency,
      });
      dispatch(makeIsLoadingFalse());
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logoutUser());
        navigate("/login");
        return toast.error("Unauthorized! Logging Out...");
      }
      toast.error("An error occurred");
      dispatch(makeIsLoadingFalse());
    }
  };
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  // This will run on initial render only
  useEffect(() => {
    getKycDetails();
  }, []);

  useEffect(() => {
    if (isLoading) {
      getKycDetails();
    }
  }, [updatedDetails, isLoading]);

  if (isLoading) {
    return (
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }
  return (
    <TransactHistoryTable
      {...KYCDetails}
      setUpdatedDetails={setUpdatedDetails}
      platformID={KYCDetails.platformID}
      kycID={KYCDetails.id}
      isLoading={isLoading}
    />
  );
}

export default TransactionHistory;
