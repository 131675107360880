import React, { useState } from "react";

import { Backdrop, CircularProgress, Typography, Box } from "@mui/material";
import { TerminalListTable } from "../components/TerminalListTable";

export const TerminalList = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zindex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

   

      <Box sx={{ marginTop: "2.5rem" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "1.5rem" }}>
          Branch terminals / transactions
        </Typography>
        <TerminalListTable isLoading={isLoading} setIsLoading={setIsLoading} />
      </Box>
    </>
  );
};
