import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import {  useSelector } from "react-redux";
import { currencyConverter } from "../utils/functionUtil";
import WithdrawFund from "./WithdrawFund";
import BranchDetailsIcon from "../assets/branchDetailsIcon.svg";
import DialogBox from "./form/DialogBox";

export const WalletHistoryTable = ({
  account_name,
  balance,
  created_at,
  isVerified,
  platformID,
  phone_number,
  address,
  country,
}) => {
  const theme = useTheme();
  const { isLoading } = useSelector((store) => store.dvaultUser);

  const { pid } = useParams();

  const [openStatus, setOpenStatus] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ID = open ? "status-popover" : undefined;

  const handleStatusClickClose = () => {
    setOpenStatus(false);
  };


  const convertDate = () => {
    if (created_at) {
      const dateObject = parseISO(created_at);
      const formattedData = format(dateObject, "yyyy-MM-dd");
      return formattedData;
    }
  };

  useEffect(() => {
    if (created_at) {
      convertDate();
    }
  }, [created_at]);
  return (
    <>
      <Paper
        sx={{
          padding: "30px",
        }}
      >
        
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          columnSpacing={1}
        >
          <Grid item>
            <Box
              sx={{
                maxWidth: "400px",
              }}
            >
              <Box
                component={"img"}
                src={BranchDetailsIcon}
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    marginLeft: "auto",
                    marginRight: "auto",
                  },

                  border: "20px solid transparent",
                  display: "block",
                  height: "7rem",
                  width: "7rem",
                  overflow: "visible",
                  borderRadius: "50%",
                  background: "#EFF1F9",
                  margin: "10px 0",
                  padding: "1rem",
                }}
              />
            </Box>
            
          </Grid>
          <Grid item>
            <Stack>
              <Box
                sx={{
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.lightBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  Branch name
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.darkBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  {account_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.lightBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  Branch phone number
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.darkBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  {phone_number}
                </Typography>
              </Box>
              
            </Stack>
          </Grid>
          <Grid item>
            <Stack>
              <Box
                sx={{
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.lightBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  Branch city
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.darkBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  {address}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.lightBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  Balance
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.darkBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  {currencyConverter(balance)}
                </Typography>
              </Box>
              
            </Stack>
          </Grid>
          <Grid item>
            <Stack>
              <Box
                sx={{
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.lightBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  Branch country
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.darkBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  {country}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.lightBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  Branch transaction volume
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: `${theme.palette.common.darkBlue}`,
                    fontWeight: 500,
                    my: 0.5,
                  }}
                >
                  {currencyConverter(balance)}
                </Typography>
              </Box>
            
            </Stack>
          </Grid>
        </Grid>
        <Box
          sx={{
            minWidth: 120,
            display: "block",
            marginLeft: "auto",
            width: 200,
          }}
        >
          <FormControl fullWidth>
            <Dialog
              open={openStatus}
              onClose={handleStatusClickClose}
              sx={{
                "&.MuiDialog-root": {},
              }}
              PaperProps={{
                sx: {
                  borderRadius: "16px",
                },
              }}
            >
              <Paper
                sx={{
                  width: "300px",
                  p: 3,
                }}
              >
             
            
                <Box
                  sx={{
                    marginTop: "15px",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      "&.MuiButton-root": {
                        width: "48%",
                        color: "rgba(21, 25, 32, 0.5)",
                        borderRadius: "10px",
                        fontWeight: 500,
                        texTransform: "capitalize",
                      },
                    }}
                    onClick={() => {
                      handleStatusClickClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      "&.MuiButton-root": {
                        width: "48%",
                        marginLeft: "10px",
                        borderRadius: "10px",
                        fontWeight: 500,
                        textTransform: "capitaize",
                      },
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={35}
                        sx={{
                          color: "common.white", 
                        }}
                      />
                    ) : isVerified ? (
                      "Unverified"
                    ) : (
                      "Verified"
                    )}
                  </Button>
                </Box>
              </Paper>
            </Dialog>
          </FormControl>
          
          <DialogBox
            buttonText="Move funds"
            buttonColor="secondary"
            title="Move funds"
          >
            {({ handleClose }) => (
              <WithdrawFund walletID={pid} handleClose={handleClose} />
            )}
          </DialogBox>
        </Box>
      </Paper>
    </>
  );
};
