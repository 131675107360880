import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormControl from "./form/FormControl";
import customFetch from "../utils/axios";
import { toast } from "react-toastify";
import { currencyConverter } from "../utils/functionUtil";
import { useSelector } from "react-redux";

function WithdrawFund({ walletID, handleClose }) {
  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;
  const initialValues = {
    amount: "",
    description: "",
    wallets: [],
  };
  const validationSchema = Yup.object({
    amount: Yup.string().required("This field is required"),
    description: Yup.string().required("Required"),
  });

  const onSubmit = async (values, onSubmitProps) => {
    try {
      const url = `/dvault/__platform_debit_wallets/?platform_id=${platformID}`;

      const payload = {
        ...values,
        wallets: [walletID],
      };
      const response = await customFetch.post(url, payload);
      onSubmitProps.resetForm();
      onSubmitProps.setFieldValue("wallets", []);
      handleClose();
      toast.success("Withdrawal successful");
      return response;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { dirty, isValid } = formik;
        return (
          <Form>
            <Box
              sx={{
                width: "400px",
              }}
            >
              <Box
                component={FormControl}
                control="textInput"
                name="amount"
                label="Amount"
                required="*"
                maxLength={10}
                value={currencyConverter(formik.values.amount)}
                onChange={(e) => {
                  formik.handleChange("amount");
                  const toNum = Number(e.target.value.replace(/[^0-9]+/g, ""));
                  formik.setFieldValue("amount", toNum);
                }}
              />

              <Box
                component={FormControl}
                control="textInput"
                name="description"
                label="Description"
                required="*"
                maxLength={10}
                value={formik.values.description}
                onChange={formik.handleChange("description")}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alightItem: "right",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!isValid || formik.isSubmitting}
                  // fullWidth
                  sx={{
                    "&.MuiButton-root": {
                      borderRadius: "7px",
                      my: 3,
                      color: "#ffffff",
                      textTransform: "capitalize",
                    },
                  }}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={35} color="secondary" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default WithdrawFund;
