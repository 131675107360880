import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";

export const getPlatformWalletBalance = createAsyncThunk(
  "platform/getPlatformWalletBalance",
  async (params, thunkAPI) => {
    const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;
    const platformWalletID = JSON.parse(
      localStorage.getItem("selectedPlatform")
    )?.wallet;
    try {
      const walletDetailsUrl = `/dvault/__wallet_detail?${platformID}&id=${platformWalletID}`;
      const response = await customFetch.get(walletDetailsUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getPlatform = createAsyncThunk(
  "platform/getPlatform",
  async (name, thunkAPI) => {
    const { id: userId } = thunkAPI.getState().dvaultUser.dvaultUser;
    try {
      const resp = await customFetch.get(`/dvault/__user_list`);
      const staffId = resp.data.find((item) => item.auth_id === userId);

      const res = await customFetch.get(
        `/dvault/__platform_list?staff_id=${staffId.id}`
      );
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue("Ooopppsss! Something went wrong");
    }
  }
);

const initialState = {
  platform: [],
  selectedPlatform: [],
  platformWalletBalance: [],
  isLoading: false,
  isLoading2: false,
  platformID: "",
  platformWalletID: "",
  showPlatforms: false,
};

const platformSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    setSelectedPlatform: (state, { payload }) => {
      localStorage.setItem("selectedPlatform", JSON.stringify(payload));
      const platform = JSON.parse(localStorage.getItem("selectedPlatform"));
      state.selectedPlatform = platform;
      state.platformID = platform?.id;
      state.platformWalletID = platform.wallet;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlatform.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPlatform.fulfilled, (state, action) => {
      state.isLoading = false;
      state.platform = action.payload;
    });
    builder.addCase(getPlatform.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getPlatformWalletBalance.pending, (state) => {
      state.isLoading2 = true;
    });
    builder.addCase(getPlatformWalletBalance.fulfilled, (state, action) => {
      state.isLoading2 = false;
      state.platformWalletBalance = action.payload.balance;
    });
    builder.addCase(getPlatformWalletBalance.rejected, (state) => {
      state.isLoading2 = false;
    });
  },
});

export const {
  setPlatformID,
  platformID,
  platformWalletID,
  setSelectedPlatform,
  isLoading,
} = platformSlice.actions;
export default platformSlice.reducer;
