import { configureStore } from "@reduxjs/toolkit";
import dvaultUserSlice, { logoutUser } from "./features/dvaultUserSlice";
import platformSlice from "./features/platform/platformSlice";
import businessBranchSlice from "./features/businessBranch/branchSlice";
import terminalSlice from "./features/terminal/terminalSlice";
import beneficierySlice from "./features/beneficiary/beneficierySlice";
import bankListSlice from "./features/bankList/bankListSlice";

export default function createStore() {
  const store = configureStore({
    reducer: {
      dvaultUser: dvaultUserSlice,
      platform: platformSlice,
      businessBranch: businessBranchSlice,
      terminals: terminalSlice,
      beneficiaries: beneficierySlice,
      bankList: bankListSlice,
    },
  });
  return store;
}

export const takeUserOut = () => {
  const store = createStore();
  store.dispatch(logoutUser());
};
