import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormControl from "../components/form/FormControl";
import customFetch from "../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const ResetPinForm = ({ successCb, closeForm }) => {
  const platformID = JSON.parse(
    localStorage.getItem("selectedPlatform")
  )?.id;
  const platformWalletID = JSON.parse(
    localStorage.getItem("selectedPlatform")
  )?.wallet;

  const initialValues = {
    token: "",
    new_pin: "",
  };
  const validationSchema = Yup.object({
    token: Yup.string().required("This field is required"),
    new_pin: Yup.string()
      .required("This field is required")
      .max(5, "PIN should be 5 characters long."),
  });

  const onSubmit = async (values, onSubmitProps) => {
    try {
      const verify_url = `/wallets/v1/${platformID}/${platformWalletID}/otp/verify/`;
      const { data } = await customFetch.post(verify_url, {
        otp: values.token,
      });

      const url = `/wallets/v1/${platformID}/${platformWalletID}/pin/reset/confirm/`;
      const { data2 } = await customFetch.post(url, {
        token: data.token,
        new_pin: values.new_pin,
      });
      onSubmitProps.resetForm();
      successCb();
      toast.success("pin reset sucessfully");
      return data2;
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { isValid, isSubmitting } = formik;
        return (
          <Form>
            <Box
              sx={{
                width: "400px",
              }}
            >
              <Box
                component={FormControl}
                control="textInput"
                name="token"
                label="Token"
                required="*"
                value={formik.values.token.replace(/[^0-9]/gi, "")}
                onChange={formik.handleChange}
              />

              <Box
                component={FormControl}
                control="textInput"
                name="new_pin"
                label="New Pin"
                required="*"
                inputProps={{
                  maxLength: 5,
                }}
                value={formik.values.new_pin.replace(/[^0-9]/gi, "")}
                onChange={formik.handleChange}
              />
              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  sx={{
                    "&.MuiButton-root": {
                      borderRadius: "7px",
                      my: 3,
                      color: "#ffffff",
                      textTransform: "capitalize",
                    },
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    "confirm"
                  )}
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
