import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormControl from "./form/FormControl";
import {  useSelector } from "react-redux";
import { toast } from "react-toastify";
import customFetch from "../utils/axios";

function ResetPasswordForm() {

  const [otpCode, setOtpCode] = useState("");



  const { dvaultUser } = useSelector((store) => store.dvaultUser);


  useEffect(() => {
    if (dvaultUser?.phone_number) {
      sendCode();
    }
  
  }, [dvaultUser]);

  const initialValues = {
    password: "",
    confirm_password: "",
    otp_code: "",
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirm_password: Yup.string("Required")
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    otp_code: Yup.string().required("Required"),
  });
  const onSubmit = async (
    { password, confirm_password, otp_code, token },
    onSubmitProps
  ) => {
    const url = `/auth/reset-password`;

    try {
      const resp = await customFetch.put(url, {
        password,
        confirm_password,
        otp_code,
        token: otpCode?.token,
      });
      toast.success("Password has been successfully reset");
      localStorage.clear();
      window.location.reload();
      onSubmitProps.resetForm();
      return resp;
    } catch (error) {
      toast.error("Password reset could not be completed, please try again.");
    }
  };

  const sendCode = async () => {
  
    const url = `/auth/__generate-otp/`;
    try {
      const resp = await customFetch.post(url, {
        phone_number: dvaultUser.phone_number,
      });
      setOtpCode(resp.data);
      toast.success("Otp has been sent to your phone number");
      return resp;
    } catch (error) {
      toast.error("Otp could not be sent");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { dirty, isValid } = formik;
        return (
          <Form>
            <Box
              sx={{
                width: "500px",
              }}
            >
              <FormControl
                control={"input"}
                label={"New Password"}
                name="password"
                type="password"
              />
              <FormControl
                control={"input"}
                label={"Confirm Password"}
                name="confirm_password"
                type="password"
              />
              <FormControl
                control={"input"}
                label={"Otp code"}
                name="otp_code"
                type="number"
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!dirty || !isValid}
                fullWidth
                sx={{
                  "&.MuiButton-root": {
                    borderRadius: "7px",
                    my: 3,
                    color: "#ffffff",
                    textTransform: "capitalize",
                  },
                }}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={35} color="secondary" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ResetPasswordForm;
