import { ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import { theme } from "./utils/theme";
import DvaultRoutes from "./utils/DvaultRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getPlatform,
  getPlatformWalletBalance,
} from "./features/platform/platformSlice";
import { getBusinessBranch } from "./features/businessBranch/branchSlice";
import { getTerminal } from "./features/terminal/terminalSlice";
import { getBeneficiaries } from "./features/beneficiary/beneficierySlice";
import { getBankList } from "./features/bankList/bankListSlice";

function App() {
  const dispatch = useDispatch();
  const getUserDetails = (state) => state.dvaultUser.dvaultUser;
  const userDetails = useSelector(getUserDetails);
  const staffId = userDetails?.id;
  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;
  const platformWalletID = JSON.parse(
    localStorage.getItem("selectedPlatform")
  )?.wallet;

  useEffect(() => {
    if (!platformID) return;
    dispatch(getBusinessBranch(platformID));
    // eslint-disable-next-line
  }, [platformID]);

  useEffect(() => {
    if (!staffId) return;
    dispatch(getPlatform());

    // eslint-disable-next-line
  }, [staffId]);
  useEffect(() => {
    if (!platformID) return;
    dispatch(getPlatformWalletBalance());
    // eslint-disable-next-line
  }, [platformID, platformWalletID]);

  useEffect(() => {
    if (!platformID) return;
    dispatch(getTerminal(platformID));
    // eslint-disable-next-line
  }, [platformID]);

  useEffect(() => {
    if (!platformWalletID) return;
    dispatch(getBeneficiaries(platformWalletID));
    // eslint-disable-next-line
  }, [platformWalletID]);

  useEffect(() => {
    dispatch(getBankList());
    // eslint-disable-next-line
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <DvaultRoutes />
    </ThemeProvider>
  );
}

export default App;
