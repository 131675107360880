import {
  Box,
  Container,
  Grid,
  useTheme,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import loginImage from "../assets/loginImage.png";
import React from "react";
import LoginForm from "../components/LoginForm";
import dukkalogo1 from "../assets/dukkalogo1.png";
function Login() {
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box>
      {matches && (
        <h3 style={{ textAlign: "center" }}>
          content only available on Desktop mode
        </h3>
      )}
      <Container
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <Stack
          sx={{
            marginTop: "20px",
          }}
        >
          <Box
            component="img"
            alt="Dvault"
            src={dukkalogo1}
            sx={{
              width: "180px",
            }}
          />
        </Stack>
        <Grid
          container
          alignItems={"center"}
          sx={{
            marginTop: "80px",
          }}
        >
          <Grid item xs={6}>
            <Box
              sx={{
                width: "100%",
                margin: "20px auto",
              }}
            >
              <Box
                component={"img"}
                src={loginImage}
                alt="Lock"
                sx={{
                  minHeight: "300px",
                  height: "28rem",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
        
          >
            <Box
            
            >
              <Typography
                variant="h3"
                color="initial"
                sx={{
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                Welcome,
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  color: "#9092A3",
                  fontWeight: 500,
                }}
              >
                Access Your Dashboard and Tools here
              </Typography>
              <LoginForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Login;
