import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormControl from "./form/FormControl";
import customFetch from "../utils/axios";
import { toast } from "react-toastify";
import { currencyConverter } from "../utils/CurrencyConverter";
import { ResetPinForm } from "./ResetPinForm";
import { BeneficiaryModal } from "./Beneficiaries/BeneficiaryModal";
import { useSelector } from "react-redux";

function TransferFundForm({ handleClose }) {
  const [beneFiciary, setBeneFiciaryF] = useState([]);
  const beneficiaries = useSelector(
    (store) => store.beneficiaries.beneficiaries
  );

  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;

  const platformWalletID = JSON.parse(
    localStorage.getItem("selectedPlatform")
  )?.wallet;

  const [showPinResetForm, setShowPinResetForm] = useState(false);

  const handlePinReset = async (payload) => {
    const url = `/wallets/v1/${platformID}/${platformWalletID}/pin/reset/`;
    try {
      const { data } = await customFetch.post(url, payload);
      setShowPinResetForm(true);
      toast.success("OTP has been sent to your registered phone number");

      return data;
    } catch (error) {
      toast.error("please provide a registered phone number");
    }
  };
  // const delayHandlePinReset = () => {
  //   setTimeout(() => {
  //     handlePinReset();
  //   }, 1000);
  // };
  const initialValues = {
    amount: "",
    wallets: [],
    wallet: "",
    beneficiary: "",
    beneficiary_account_number: "",
    beneficiary_bank_code: "",
    beneficiary_account_name: "",
    narration: "",
    currency: "NGN",
    pin: "",
  };
  const validationSchema = Yup.object({
    amount: Yup.string().required("This field is required"),
    narration: Yup.string().required("This field is required"),
    beneficiary: Yup.string().required("This field is required"),
    pin: Yup.string().required("This field is required"),
  });

  const getBenficiary = async () => {
    try {
      const mapped = beneficiaries?.map((item) => {
        return {
          key: `${item.account_name} (${item.account_number})`,
          value: item.account_name,
          ...item,
        };
      });
      setBeneFiciaryF(mapped);
      return mapped;
    } catch (error) {}
  };
  useEffect(() => {
    getBenficiary();
    // eslint-disable-next-line
  }, []);
  const url = `/dvault/__transfer_funds`;
  const onSubmit = async (values, onSubmitProps) => {
    try {
      let wallet;
      if (typeof values.wallet === "string") {
        wallet = [platformWalletID];
      }
      const payload = {
        ...values,
        wallet: platformWalletID,
        beneficiary_account_number: values.beneficiary_account_number,
        beneficiary_bank_code: values.beneficiary_bank_code,
        beneficiary_account_name: values.beneficiary_account_name,
        currency: "NGN",
      };

      const response = await customFetch.post(url, payload);
      onSubmitProps.resetForm();
      handleClose();
      toast.success("Withdraw Successful");
      return response;
    } catch (error) {
      for (const [key] of Object.entries(error.response.data.error)) {
        if (key === "wallet") {
          return toast.error(error.response.data.error.wallet.toString());
        } else if (key === "pin") {
          return toast.error(error.response.data.error.pin.toString());
        } else if (key === "amount") {
          return toast.error(error.response.data.error.amount.toString());
        } else {
          return toast.error("something went wrong, please try again later 😔");
        }
      }
    }
  };
  return (
    <>
      {showPinResetForm ? null : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            const { isValid } = formik;
            return (
              <Form>
                <Box
                  sx={{
                    width: "400px",
                  }}
                >
                  <Box
                    component={FormControl}
                    control="textInput"
                    name="amount"
                    label="Amount"
                    required="*"
                    value={currencyConverter(formik.values.amount)}
                    onChange={(e) => {
                      formik.handleChange("amount");
                      const toNum = Number(
                        e.target.value.replace(/[^0-9]+/g, "")
                      );
                      formik.setFieldValue("amount", toNum);
                    }}
                  />

                  <Box
                    component={FormControl}
                    control="select"
                    name="beneficiary"
                    label="Beneficiary"
                    // required="*"
                    options={beneFiciary}
                    value={formik.values.beneficiary}
                    onChange={(e) => {
                      formik.handleChange("beneficiary")(e);
                      const selectedBeneficiary = beneFiciary.find(
                        (beneficiary) => beneficiary.value === e.target.value
                      );
                      if (selectedBeneficiary) {
                        formik.setValues({
                          ...formik.values,
                          beneficiary: e.target.value,
                          beneficiary_account_name:
                            selectedBeneficiary.account_name,
                          beneficiary_account_number:
                            selectedBeneficiary.account_number,
                          beneficiary_bank_code: selectedBeneficiary.bank_code,
                        });
                      } else {
                        formik.setValues({
                          ...formik.values,
                          beneficiary_account_name: "",
                          beneficiary_account_number: "",
                          beneficiary_bank_code: "",
                        });
                      }
                    }}
                  />

                  <Box
                    component={FormControl}
                    control="textInput"
                    name="narration"
                    label="Description"
                    value={formik.values.narration}
                    onChange={formik.handleChange("narration")}
                  />

                  <Box
                    component={FormControl}
                    control="textInput"
                    name="pin"
                    label="pin"
                    required="*"
                    inputProps={{
                      maxLength: 5,
                    }}
                    value={formik.values.pin.replace(/[^0-9]/gi, "")}
                    onChange={formik.handleChange("pin")}
                  />

                  <Button
                    onClick={() => {
                      handlePinReset();
                    }}
                    variant="text"
                    color="secondary"
                  >
                    Forgot pin?
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      alightItem: "right",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={!isValid || formik.isSubmitting}
                      sx={{
                        "&.MuiButton-root": {
                          borderRadius: "7px",
                          my: 3,
                          color: "#ffffff",
                          textTransform: "capitalize",
                        },
                      }}
                    >
                      {formik.isSubmitting ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        "Transfer"
                      )}
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
      {showPinResetForm && (
        <BeneficiaryModal
          OpenModal={showPinResetForm}
          handleClose={() => {
            setShowPinResetForm(false);
          }}
          modalTitle="Confirm password reset"
        >
          <ResetPinForm successCb={() => setShowPinResetForm(false)} />
        </BeneficiaryModal>
      )}
    </>
  );
}

export default TransferFundForm;
