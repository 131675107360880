import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";


const initialState = {
  terminals: [],
  isLoading: true,
};

function createData(sn, id, account_name, serial_number, branch_name) {
  return {
    sn,
    id,
    account_name,
    serial_number,
    branch_name,
  };
}

export const getTerminal = createAsyncThunk(
  "terminal/getTerminal",
  async (platformID, thunkAPI) => {
    try {
   
      let url = `/dvault/__pos_terminal_list?platform_id=${platformID}`;

      const res = await customFetch.get(url);
   
      const newData = res.data.results.map((dataItem, index) => {

        const {
          id,
          serial_number,
        } = dataItem;


        let accountName = dataItem.wallet?.account_name;
        return createData(
          index + 1,
          id,
          accountName || "No name",
          serial_number || "serial"
        );
      });

      return newData;
    } catch (error) {
      thunkAPI.rejectWithValue("Ooopppsss! Something went wrong");
    }
  }
);

const terminalSlice = createSlice({
  name: "terminals",
  initialState,
  reducers: {
    setLocalSearch: (state, action) => {
      state.localSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTerminal.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTerminal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.terminals = action.payload;
    });
    builder.addCase(getTerminal.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setLocalSearch } = terminalSlice.actions;
export default terminalSlice.reducer;
