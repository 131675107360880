import React from "react";
import { Typography } from "@mui/material";
import { AllTerminalListTable } from "../components/AllTerminalListTable";
import { useNavigate } from "react-router-dom";

export const AllTerminalList = () => {
 const navigate= useNavigate()

  return (
    <>
    
      <Typography
        sx={{ fontWeight: "500", fontSize: "2.0rem", margin: "2.5rem 0", cursor:"pointer" }}
        onClick={()=>navigate("/dashboard")}
      >
        &lt; All Terminals
      </Typography>
      <AllTerminalListTable />
    </>
  );
};
