import React, { useState } from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import DashboardTableReuse from "../components/ReusableTransTable";
import { useNavigate } from "react-router-dom";

export const TransactionList = () => {
  const [isLoading, setIsLoading] = useState(true);
const navigate = useNavigate()
  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zindex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Typography
        sx={{ fontWeight: "500", fontSize: "2.0rem", margin: "2.5rem 0", cursor:"pointer" }}
        onClick={()=>navigate("/dashboard")}
      >
        &lt; All Platform transactions
      </Typography>

      <DashboardTableReuse isLoading={isLoading} setIsLoading={setIsLoading} />
    </>
  );
};
