import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import FormControl from "./form/FormControl";
import { loginUser } from "../features/dvaultUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openForgetPassword, setOpenForgetPassword] = React.useState(false);

  const handleClickOpen = () => {
    setOpenForgetPassword(true);
  };

  const handleClose = () => {
    setOpenForgetPassword(false);
  };
  const { dvaultUser } = useSelector((store) => store.dvaultUser);

  useEffect(() => {
    if (dvaultUser) {
      return navigate("/");
    }
  }, [dvaultUser, navigate]);
  const initialValues = {
    credential: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    credential: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    dispatch(loginUser(values));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { dirty, isValid } = formik;

        return (
          <Form>
            <Box>
              <FormControl control={"input"} label="Username" name="credential" />
              <FormControl
                control={"input"}
                label={"Password"}
                name="password"
                type="password"
              />

              <Box>
                <Button
                  variant="text"
                  onClick={handleClickOpen}
                  sx={{ marginTop: "1rem" }}
                >
                  Forgot password
                </Button>
                <Dialog
                  open={openForgetPassword}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <Typography
                      variant="h6"
                      color="initial"
                      sx={{
                        textAlign: "center",
                        fontWeight: 500,
                      }}
                    >
                      Kindly reach out to our{" "}
                      <a
                        href="mailto:support@dukka.com"
                        style={{ textDecoration: "none", color: "#E97D80" }}
                      >
                        support team
                      </a>{" "}
                      on how to reset your password. Thank You.
                    </Typography>
                  </DialogContent>
                </Dialog>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!dirty || !isValid}
                fullWidth
                sx={{
                  "&.MuiButton-root": {
                    borderRadius: "7px",
                    my: 3,
                    color: "#ffffff",
                    textTransform: "capitalize",
                  },
                }}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={35} color="secondary" />
                ) : (
                  "Login"
                )}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default LoginForm;
