import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customFetch from "../utils/axios";
import { toast } from "react-toastify";
import { getUserFromLocalStorage } from "../utils/localStorage";

const initialState = {
  isLoading: true,
  dvaultUser: getUserFromLocalStorage(),
  isUserLoggedIn: false,
  pageNumber: 0,
  staffId: "",
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (dvaultUser, thunkAPI) => {
    try {
      const resp = await customFetch.post("/auth/login/", dvaultUser);

      localStorage.setItem("access_token", JSON.stringify(resp.data.token));
      localStorage.setItem(
        "refresh_token",
        JSON.stringify(resp.data.refresh_token)
      );
      let userDetails = {
        email: resp.data.user.email,
        phone_number:resp.data.user.phone_number,
        tokens: resp.data.token,
        id: resp.data.user.id,
        firstName: resp.data.user.first_name,
        lastName: resp.data.user.last_name,
        userName: resp.data.user.username,
        resetPassword: resp.data.user.reset_password,
      };

      return userDetails;
    } catch (error) {
     
      if (error.response.data.detail) {
        return thunkAPI.rejectWithValue(error.response.data.detail);
      }
      return thunkAPI.rejectWithValue("An error occured!");
    }
  }
);

const dvaultUserSlice = createSlice({
  name: "dvaultUser",
  initialState,
  reducers: {
    logoutUser: (state, { payload }) => {
      localStorage.removeItem("dvaultUser");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("selectedPlatform");
      state.dvaultUser = null;
      state.isUserLoggedIn = false;
      state.isLoading = false;
    },
    makeIsLoadingTrue: (state, { payload }) => {
      state.isLoading = true;
    },
    makeIsLoadingFalse: (state, { payload }) => {
      state.isLoading = false;
    },
    changePageNumber: (state, { payload }) => {
      state.pageNumber = payload;
    },
    staffId: (state, { payload }) => {
      state.staffId = payload.id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.dvaultUser = payload;
        state.staffId = payload.id;
        state.isUserLoggedIn = true;
        toast.success("You are logged in");
        localStorage.setItem("dvaultUser", JSON.stringify(payload));
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.dvaultUser = null;
        state.isUserLoggedIn = false;

        toast.error(payload);
      });
  },
});
export const {
  logoutUser,
  makeIsLoadingTrue,
  makeIsLoadingFalse,
  changePageNumber,
  staffId,
} = dvaultUserSlice.actions;
export default dvaultUserSlice.reducer;
