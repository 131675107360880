import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  InputAdornment,
  List,
  ListItemButton,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useTheme,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import CancelIcon from "@mui/icons-material/Cancel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import customFetch from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import  {
  changePageNumber,
  logoutUser,
} from "../features/dvaultUserSlice";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import { dateConverter, dateConverter2 } from "../utils/functionUtil";
import { currencyConverter } from "../utils/functionUtil";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const columns = [
  { id: "id", label: "ID", minWidth: 170, align: "left" },
  { id: "description", label: "Description", minWidth: 170, align: "left" },
  {
    id: "amount",
    label: "Amount",
    minWidth: 100,
    align: "left",
  },


  {
    id: "payment_method",
    label: "Payment Method",
    minWidth: 100,
    align: "left",
  },

  { id: "created_at", label: "Date", minWidth: 170, align: "left" },

  { id: "status", label: "Status", minWidth: 170, align: "left" },
];

function createData(
  id,
  description,
  amount,
  payment_method,
  created_at,
  status
  
) {
  return {
    id,
    description,
    amount,
    payment_method,
    created_at,
    status,
  
  };
}

function ReusableTransTable({
  isLoading,
  setIsLoading,
  terminal_id,
  wallet_id,
  tid,
}) {
 
  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id

  const dispatch = useDispatch();
  const { pageNumber } = useSelector((store) => store.dvaultUser);
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const [page, setPage] = useState(pageNumber);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [allKYC, setAllKYC] = useState([]);
  const currentID = useRef("");
  const [localSearch, setLocalSearch] = useState("");
  const [anchorEl, setAnchorEL] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    setPage(0);
    dispatch(changePageNumber(0));

    if (ranges.selection.startDate && ranges.selection.endDate) {

    } else if (ranges.selection.startDate && !ranges.selection.endDate) {
      setDateRangeAnchorEl(dateRangeAnchorEl); 
    }
  };

  const handleClick = (event) => {
    setDateRangeAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setDateRangeAnchorEl(null);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    setDateRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const getAllKYC = async () => {


    let url = `/dvault/__transaction_history_list?platform_id=${platformID}&page_size=${rowsPerPage}&page=${
      page + 1
    }`;
    setIsLoading(true);
    try {
      if (terminal_id) {
        url = url + `&terminal_id=${terminal_id}`;
      }

      if (wallet_id) {
        url = url + `&wallet_id=${wallet_id}`;
      }

      if (localSearch) {
        url = url + `&search=${localSearch}`;
      }
      if (transactionStatus === "SUCCESS") {
        url = url + `&status=SUCCESS`;
      }
      if (transactionStatus === "FAILED") {
        url = url + `&status=FAILED`;
      }
      if (transactionStatus === "INITIALIZED") {
        url = url + `&status=INITIALIZED`;
      }
      if (dateRange.startDate && dateRange.endDate) {
        url =
          url +
          `&created_at=${dateConverter2(dateRange.startDate)},${dateConverter2(
            dateRange.endDate
          )}`;
      }

      const resp = await customFetch.get(url);
      const newData = resp.data.results.map((kycDetail, index) => {
        const {
          id,
          description,
          amount,
          payment_method,
          created_at,
          status,
       
        } = kycDetail;

   


     

        return createData(
          id,
          description ?? "no extra information",
          currencyConverter(amount),
          payment_method,
          dateConverter(created_at),
          status
        );
      });
      setIsLoading(false);
      setAllKYC(newData);
      setTotalCount(resp.data.count);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logoutUser());
        navigate("/login");
        return toast.error("Unauthorized! Logging Out...");
      }
      toast.error("An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(changePageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    setRowsPerPage(+event.target.value);
    setPage(pageNumber);
  };

  const handleLocalSearchChange = (e) => {
    setLocalSearch(e.target.value);
    setPage(0);
    dispatch(changePageNumber(0));
  };
  const handleFilterChange = (e, filterInput) => {
    setPage(0);
    dispatch(changePageNumber(0));
    setTransactionStatus(filterInput);
    handleFilterClose(e);
  };

  const handleFilterClick = (e) => {
    setAnchorEL(e.currentTarget);
  };

  const handleFilterClose = (e) => {
    setAnchorEL(null);
  };


  const arrayToCSV = (data) => {
    const csvRows = [];

    const header = Object.keys(data[0]);
    csvRows.push(header.join(","));

    data.forEach((obj) => {
      const values = Object.values(obj);
      const csvRow = values.map((value) => JSON.stringify(value));
      csvRows.push(csvRow.join(","));
    });

    return csvRows.join("\n");
  };

  const handleDownload = async () => {
    const fileName = window.prompt("Enter the filename") || "";
    if (!fileName) {
      alert("Please enter a filename");
      return;
    }

    try {

      const csvContent = arrayToCSV(allKYC);
   

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    if (!platformID) {
      return;
    }
    const debounceId = setTimeout(() => {
      getAllKYC();
    }, 750);
    return () => clearTimeout(debounceId);
     // eslint-disable-next-line
  }, [page, localSearch, transactionStatus, dateRange, platformID, tid]);

  let buttonLabel =
    dateRange.startDate && dateRange.endDate
      ? `${dateRange.startDate.toDateString()} - ${dateRange.endDate.toDateString()}`
      : "Choose Date Range";
  let today = new Date();

  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Box
     
      >
        <Box
          sx={{
            width: "100%",
           
          }}
        >
          <Paper elevation={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="left"
              px={4}
              py={1.8}
            >
              {/* date range */}
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleClick}
                  startIcon={<CalendarMonthIcon />}
                  endIcon={<CancelIcon onClick={handleCancelClick} />}
                  color={"secondary"}
                  sx={{ fontSize: { md: "0.7rem", lg: "1rem" } }}
                >
                  {buttonLabel}
                </Button>

                <Popover
                  open={Boolean(dateRangeAnchorEl)}
                  anchorEl={dateRangeAnchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <DateRangePicker
                    ranges={[dateRange]}
                    onChange={handleSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    months={2}
                    maxDate={today}
                    showPreview={true}
                  />
                </Popover>
              </Box>
              {/* search */}
              <Box sx={{ margin: "0 2rem" }}>
                <TextField
                  id="localSearch"
                  color="accent"
                  value={localSearch}
                  onChange={handleLocalSearchChange}
                  sx={{
                    "& .MuiTextField-root": {
                      width: 200,
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "39px",
                    },
                    "& .MuiOutlinedInput-input": {
                      height: "0.4rem",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ cursor: "pointer" }}
                      >
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    placeholder: "Search",
                  }}
                />
              </Box>
              {/* filter */}
              <Box sx={{ marginRight: "1rem" }}>
                <Button
                  variant="outlined"
                  startIcon={<FilterListIcon />}
                  color={"accent"}
                  aria-describedby={id}
                  onClick={handleFilterClick}
                >
                  Filter
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleFilterClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box
                    sx={{
                      width: "150px",
                    }}
                  >
                    <List>
                      <ListItemButton
                        onClick={(e) => handleFilterChange(e, "")}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          All
                        </Typography>
                      </ListItemButton>
                      <ListItemButton
                        onClick={(e) => handleFilterChange(e, "SUCCESS")}
                      >
                        <Chip
                          label="SUCCESS"
                          sx={{
                            "&.MuiChip-root": {
                              background: `${theme.palette.common.green}`,
                              color: `${theme.palette.common.greenIcon}`,
                              fontWeight: 500,

                              marginLeft: "auto",
                              marginRight: "auto",
                            },
                          }}
                        />
                      </ListItemButton>
                      <ListItemButton
                        onClick={(e) => handleFilterChange(e, "FAILED")}
                      >
                        <Chip
                          label="FAILED"
                          sx={{
                            "&.MuiChip-root": {
                              background: `${theme.palette.common.flagRed}`,
                              color: `${theme.palette.common.purpleIcon}`,
                              fontWeight: 500,
                              marginLeft: "auto",
                              marginRight: "auto",
                            },
                          }}
                        />
                      </ListItemButton>
                      <ListItemButton
                        onClick={(e) => handleFilterChange(e, false)}
                      >
                        <Chip
                          label="INITIALIZED"
                          sx={{
                            "&.MuiChip-root": {
                              background: `${theme.palette.common.purple}`,
                              color: `${theme.palette.common.purpleIcon}`,
                              fontWeight: 500,
                              marginLeft: "auto",
                              marginRight: "auto",
                            },
                          }}
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </Popover>
              </Box>
         
              {/* Download */}
              <Button
                variant="contained"
                endIcon={<FileDownloadIcon />}
                color="accent"
                sx={{
                  marginLeft: "10px",
                  fontSize: {
                    md: "0.7rem",
                    lg: "1rem",
                  },
                }}
                onClick={() => handleDownload()}
              >
                Download report
              </Button>
            </Stack>
          </Paper>
        </Box>

        {allKYC && (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#EFF1F9",
                      },
                    }}
                  >
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allKYC.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover
                        onMouseOver={() => {
                          currentID.current = `${row.id}`;
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                            
                            >
                              {value === "SUCCESS" ? (
                                <Chip
                                  label="Success"
                                  sx={{
                                    "&.MuiChip-root": {
                                      background: `${theme.palette.common.green}`,
                                      color: `${theme.palette.common.greenIcon}`,
                                      fontWeight: 500,
                                    },
                                  }}
                                />
                              ) : value === "FAILED" ? (
                                <Chip
                                  label="Failed"
                                  sx={{
                                    "&.MuiChip-root": {
                                      background: `${theme.palette.common.flagRed}`,
                                      color: `${theme.palette.common.purpleIcon}`,
                                      fontWeight: 500,
                                    },
                                  }}
                                />
                              ) : value === "INITIALIZED" ? (
                                <Chip
                                  label="Initialized"
                                  sx={{
                                    "&.MuiChip-root": {
                                      background: `${theme.palette.common.purple}`,
                                      color: `${theme.palette.common.flagIcon}`,
                                      fontWeight: 500,
                                    },
                                  }}
                                />
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              // rowsPerPageOptions={[20]}
              component="div"
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
    </>
  );
}

export default ReusableTransTable;
