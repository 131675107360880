import { FieldArray, ErrorMessage } from "formik";
import { Box, Typography, MenuItem, Select } from "@mui/material";
import { TextError } from "./TextError";

const SelectComponent = ({ label, name, options, value, ...rest }) => {
  return (
    <Box>
      <Typography
        component="label"
        htmlFor={name}
        sx={{ fontSize: "1rem", fontWeight: 500, color: "#454E68" }}
      >
        {label}
      </Typography>
      <FieldArray name={name}>
        {({ field }) => (
          <Select
            defaultValue=""
            {...field}
            {...rest}
            fullWidth
            style={{
              height: "42px",
              outline: "none",
              borderColor: " #c4c4c4",
              margin: "0.5rem 0 1.5rem 0",
       
            }}
          >
            {[{ key: "Select...", value: "" }, ...options]?.map((option) => {
              return (
                <MenuItem
                  key={option.key}
                  value={option.value}
                  disabled={option.value === "" ? true : false}
                  sx={{ backgroundColor: "#f9f9f9" }}
                >
                  {option.key}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FieldArray>
      <ErrorMessage component={TextError} name={name} />
    </Box>
  );
};

export default SelectComponent;
