import React, { useState } from "react";
import { Backdrop, CircularProgress, Typography, Box } from "@mui/material";
import DashboardTable from "./DashboardTable";

export const TransactionHistoryDetails = ({ wallet_id, KYCDetails }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zindex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Box sx={{ marginTop: "2.5rem" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "1.5rem",marginBottom:"1.5rem" }}>
          {KYCDetails.account_name}'s transactions
        </Typography>
        <DashboardTable isLoading={isLoading} wallet_id={wallet_id} setIsLoading={setIsLoading} />
       
      </Box>
    </>
  );
};
