import {
  Box,
  Paper,
  Grid,
  Stack,
  Typography,
  useTheme,
  Button,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import terminalIcon from "../assets/pos.svg";
import branchIcon from "../assets/branch_icon.svg";
import TransferFundForm from "./TransferFundForm";
import { currencyConverter } from "../utils/functionUtil";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DialogBox from "./form/DialogBox";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import customFetch from "../utils/axios";
import ConfirmationModal from "./form/ConfirmationModal";
import { toast } from "react-toastify";

const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;

export function TotalBalanceIndicator({
  src,
  total,
  bgColor,
  text,
  handleClose,
}) {
  const theme = useTheme();
  const platformWalletBalance = useSelector(
    (store) => store.platform.platformWalletBalance
  );
  const [showBalance, setShowBalance] = useState(false);
  const handleClickShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handleMouseDownBalance = (event) => {
    event.preventDefault();
  };

  const balanceDisplay = showBalance
    ? currencyConverter(platformWalletBalance)
    : "*".repeat(String(platformWalletBalance)?.length);

  return (
    <Paper
      sx={{
        borderRadius: "13px",
        px: 2,
        py: 2,
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box component={"span"}>
            <Typography sx={{ fontSize: { md: "0.7rem", lg: "1rem" } }}>
              Total balance
            </Typography>

            <IconButton
              aria-label="toggle balance visibility"
              onClick={handleClickShowBalance}
              onMouseDown={handleMouseDownBalance}
              edge="end"
              sx={{ fontSize: { md: "0.7rem", lg: "1rem" } }}
            >
              {showBalance ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            <Typography
              variant="h4"
              sx={{
                [theme.breakpoints.down("lg")]: {
                  textAlign: "left",
                },
                fontWeight: 500,
                fontSize: { md: "0.7rem", lg: "1.5rem" },
              }}
            >
              {balanceDisplay}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{}}>
          <Box>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link to="/transactions">
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    fontSize: { md: "0.5rem", lg: "0.7rem" },
                    width: "max-content",
                    marginBottom: "1rem",
                  }}
                >
                  View transactions
                </Button>
              </Link>

              <DialogBox
                buttonText="Move funds"
                buttonColor="secondary"
                title="Move funds"
              >
                {({ handleClose }) => (
                  <TransferFundForm handleClose={handleClose} />
                )}
              </DialogBox>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export function BranchIndicator() {
  const theme = useTheme();
  const businessBranch = useSelector(
    (store) => store.businessBranch.businessBranch
  );

  return (
    <Paper
      sx={{
        borderRadius: "13px",
        px: 2,
        py: 2,
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Box component={"span"}>
            <Box
              component={"img"}
              src={branchIcon}
              sx={{
                [theme.breakpoints.down("lg")]: {
                  marginLeft: "auto",
                  marginRight: "auto",
                },

                border: "20px solid transparent",
                display: "block",
                height: { md: "2.5rem", lg: "2.7rem" },
                width: { md: "2.5rem", lg: "2.7rem" },
                overflow: "visible",
                borderRadius: "50%",
                background: `#FFDBE4`,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6.9}>
          <Stack>
            <Typography sx={{ fontSize: { md: "0.7rem", lg: "1rem" } }}>
              Total branches
            </Typography>
            <Typography
              variant="h4"
              sx={{
                [theme.breakpoints.down("lg")]: {
                  textAlign: "center",
                },
                fontWeight: 500,
              }}
            >
              {businessBranch.length}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
export function TerminalIndicator() {
  const terminals = useSelector((store) => store.terminals.terminals);
  const theme = useTheme();

  return (
    <Paper
      sx={{
        borderRadius: "13px",
        px: 2,
        py: 2,
        height: "100px",
      }}
    >
      <Grid
        container
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box component={"span"}>
            <Box
              component={"img"}
              src={terminalIcon}
              sx={{
                [theme.breakpoints.down("lg")]: {
                  marginLeft: "auto",
                  marginRight: "auto",
                },

                border: "20px solid transparent",
                display: "block",
                height: { md: "2.5rem", lg: "2.7rem" },
                width: { md: "2.5rem", lg: "2.7rem" },
                overflow: "visible",
                borderRadius: "50%",
                background: "#E8DEF8",
                margin: "10px 0",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Typography sx={{ fontSize: { md: "0.7rem", lg: "1rem" } }}>
            Total terminals
          </Typography>
          <Typography
            variant="h4"
            sx={{
              [theme.breakpoints.down("lg")]: {
                textAlign: "center",
              },
              fontWeight: 500,
            }}
          >
            {terminals.length}
          </Typography>
          <Stack>
            <Link to="/terminals">
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  fontSize: { md: "0.6rem", lg: "0.8rem" },
                  backgroundColor: "black",
                  width: "max-content",
                }}
              >
                View terminals
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}

export function TotalTransactionIndicator() {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [getConfirmation, setGetConfirmation] = useState(false);
  const businessBalance = useSelector(
    (store) => store.businessBranch.businessBalance
  );

  const [showBalance, setShowBalance] = useState(false);

  const handleClickShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handleMouseDownBalance = (event) => {
    event.preventDefault();
  };
  const autoSweepFund = async () => {
    try {
      const resp = await customFetch.get(
        `/dvault/__auto_sweep?platform_id=${platformID}`
      );
      toast.success("Wallets swept successfully");
      setOpenConfirmationModal(false);
      return resp;
    } catch (error) {
      toast.error("Auto Sweep Failed");
    }
  };

  return (
    <Paper
      sx={{
        borderRadius: "13px",
        px: 2,
        py: 2,
        height: "100px",
        marginTop: "2.5rem",
      }}
    >
      <Grid container alignItems={"center"} justifyContent="space-between">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>
              Transaction balance
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label="toggle balance visibility"
                onClick={handleClickShowBalance}
                onMouseDown={handleMouseDownBalance}
                edge="end"
                sx={{ fontSize: "1.2rem", marginRight: "1rem" }}
              >
                {showBalance ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {showBalance ? (
                <span style={{ fontSize: "2rem", letterSpacing: "3px" }}>
                  {currencyConverter(businessBalance)}
                </span>
              ) : (
                <span style={{ fontSize: "2rem", paddingTop: "0.3rem" }}>
                  {"* ".repeat(currencyConverter(businessBalance).length)}
                </span>
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              fontSize: { md: "0.5rem", lg: "0.7rem" },
              width: "max-content",
              marginBottom: "1rem",
            }}
            onClick={() => {
              setOpenConfirmationModal(true);
            }}
          >
            Auto Sweep
          </Button>
        </Grid>
      </Grid>
      <ConfirmationModal
        modalTitle="Are you sure you want to auto sweep the funds ?"
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        getConfirmation={getConfirmation}
        setGetConfirmation={setGetConfirmation}
        runFunc={autoSweepFund}
      />
    </Paper>
  );
}

export function PlatformCard({ platform }) {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        borderRadius: "13px",
        px: 2,
        py: 2,
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: `#FFDBE4`,
      }}
    >
      <Stack>
        <Typography
          variant="h4"
          sx={{
            [theme.breakpoints.down("lg")]: {
              textAlign: "center",
            },
            fontWeight: 500,
          }}
        >
          {platform.name}
        </Typography>
      </Stack>
    </Paper>
  );
}
