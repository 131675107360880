import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import customFetch from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, makeIsLoadingFalse } from "../features/dvaultUserSlice";
import { WalletHistoryTable } from "../components/WalletHistoryTable";

export const BeneficiaryDetail = () => {
  const platformWalletID = JSON.parse(
    localStorage.getItem("selectedPlatform")
  )?.wallet;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => store.dvaultUser);
  const [setUpdatedDetails] = useState({});
  const [KYCDetails, setKYCDetails] = useState({
    id: "",
    account_name: "",
    account_number: "",
    balance: "",
    status: "",
    paymentMethod: "",
    type: "",
    description: "",
    reference_id: "",
    wallet: "",
    sender: "",
    charges: "",
    charges_amount: "",
    amount: "",
    currency: "",
    isLoading: true,
  });

  let wallet_id = platformWalletID;
  let { id } = useParams();

  const getKycDetails = async () => {
    let url = `/wallets/v1/${wallet_id}/beneficiary/${id}/`;
    try {
      const res = await customFetch.get(url);
      const {
        id,
        account_name,
        account_number,
        balance,
        created_at,
        updated_at,
        currency,
      } = res.data;

      setKYCDetails({
        id,
        account_name,
        account_number,
        balance,
        created_at,
        updated_at,
        currency,
      });

      dispatch(makeIsLoadingFalse());
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logoutUser());
        navigate("/login");
        return toast.error("Unauthorized! Logging Out...");
      }
      toast.error("An error occurred");
      dispatch(makeIsLoadingFalse());
    }
  };

  useEffect(() => {
    getKycDetails();
  }, []);

  if (isLoading) {
    return (
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }
  return (
    <WalletHistoryTable
      statusProp="verified"
      {...KYCDetails}
      setUpdatedDetails={setUpdatedDetails}
      platformID={KYCDetails.platformID}
      kycID={KYCDetails.id}
      isLoading={isLoading}
    />
  );
};
