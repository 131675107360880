import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { currencyConverter } from "../../utils/functionUtil";

const initialState = {
  businessBranch: [],
  isLoading: false,
  businessBalance: "",
  localSearch: "",
};

function createData(sn, id, account_name, account_number, balance) {
  return { sn, id, account_name, account_number, balance };
}

export const getBusinessBranch = createAsyncThunk(
  "businessBranch/getBusinessBranch",
  async (platformID, thunkAPI) => {
    try {
    
      let url = `/dvault/__wallet_list_external?platform_id=${platformID}`;
      const res = await customFetch.get(url);
      const newData = res?.data?.results.map((dataItem, index) => {
        const { id, account_name, account_number, balance, phone_number } =
          dataItem;
        return createData(
          index + 1,
          id,
          account_name.replace(/Merchant|testaccount|\)|\(/gi, ""),
          account_number,
          currencyConverter(balance)
        );
      });
      const businessBalance = res?.data?.results.map((branchBalance) => {
        const { balance } = branchBalance;
        return Number(balance);
      });
      const totalAmount = businessBalance.reduce((acc, cur) => acc + cur, 0);

      return { newData, totalAmount };
    } catch (error) {
      thunkAPI.rejectWithValue("Ooopppsss! Something went wrong");
    }
  }
);

const businessBranchSlice = createSlice({
  name: "businessBranch",
  initialState,
  reducers: {
    setLocalSearch: (state, action) => {
      state.localSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessBranch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBusinessBranch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.businessBranch = action.payload.newData;
      state.businessBalance = action.payload.totalAmount;
    });
    builder.addCase(getBusinessBranch.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setLocalSearch, isLoading } = businessBranchSlice.actions;
export default businessBranchSlice.reducer;
