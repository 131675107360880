import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { changePageNumber } from "../../features/dvaultUserSlice";
import { BeneficiaryModal as DeleteficiaryModal } from "./BeneficiaryModal";
import { BeneficiaryModal as CreateOrEditBeneficiaryModal } from "./BeneficiaryModal";
import { BeneficiaryForm } from "./BeneficiaryForm";
import { deleteBeneficiaryAsync } from "../../features/beneficiary/beneficierySlice";
import { Beneficiaries } from "../../pages/Beneficiaries";

const columns = [
  { id: "sn", label: "SN", minWidth: 70, align: "left" },
  {
    id: "account_name",
    label: "Account Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "account_number",
    label: "Account Number",
    minWidth: 100,
    align: "left",
  },
  { id: "bank_name", label: "Bank Name", minWidth: 170, align: "left" },
];

export const BeneficiaryListTable = () => {
  const beneficiaries = useSelector(
    (store) => store.beneficiaries?.beneficiaries
  );

  const { isLoading } = useSelector((store) => store.beneficiaries);
  const [beneficiaryToDelete, setBeneficiaryToDelete] = useState("");
  const [openDeleteMOdal, setOpenDeleteMOdal] = useState(false);
  const platformWalletID = JSON.parse(
    localStorage.getItem("selectedPlatform")
  )?.wallet;
  const dispatch = useDispatch();
  const currentID = useRef("");
  const [localSearch, setLocalSearch] = useState("");
  const [currentPopover, setCurrentPopover] = useState({
    id: null,
    anchorEl: null,
  });
  const [beneficiaryFormState, setBeneficiaryFormState] = useState("create");
  const [showbeneficiaryForm, setShowBeneficiaryForm] = useState(false);

  const handlePopoverOpen = (event, id) => {
    setCurrentPopover({
      id,
      anchorEl: event.target,
    });
  };

  const handlePopoverClose = () => {
    setCurrentPopover({
      id: null,
      anchorEl: null,
    });
  };

  const handleLocalSearchChange = (e) => {
    setLocalSearch(e.target.value);
    dispatch(changePageNumber(0));
  };

  const deleteBeneficiary = async (beneficiaryId) => {
    dispatch(deleteBeneficiaryAsync({ platformWalletID, beneficiaryId }))
      .then(() => {
        // toast.success("Beneficiary deleted successfully");
        setOpenDeleteMOdal(false);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      {/* <Box
        sx={{
          marginTop: "27px",
          marginBottom: "10px",
        }}
      ></Box> */}

      <Box sx={{ marginTop: "10px" }}>
        <Box sx={{ width: "100%", marginBottom: "30px" }}>
          <Paper elevation={1}>
            <Stack direction="row" justifyContent="left" py={1.8}>
              <Box>
                <Button
                  variant="contained"
                  color={"secondary"}
                  onClick={() => {
                    // setBeneficiaryToEdit(null);
                    setBeneficiaryFormState("create");
                    setShowBeneficiaryForm(true);
                  }}
                  sx={{
                    marginLeft: "16px",
                    borderRadius: "10px",
                  }}
                >
                  Add beneficiary
                  <Typography
                    component="span"
                    sx={{ fontSize: "18px", marginLeft: "12px" }}
                  >
                    +
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Box>
        {beneficiaries && (
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
              }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      backgroundColor: "#EFF1F9",
                    },
                  }}
                >
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell key="extra" align="left" style={{ minWidth: 170 }}>
                    perform action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {beneficiaries.length < 1 ? (
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }}>
                      No Beneficiary to display
                    </TableCell>
                  </TableRow>
                ) : (
                  beneficiaries?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover
                        onMouseOver={() => {
                          currentID.current = `${row.id}`;
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          const label = column.label;
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="secondary"
                            endIcon={<UnfoldLessIcon />}
                            aria-owns="mouse-over-popover"
                            onClick={(e) => handlePopoverOpen(e, row.id)}
                            sx={{ color: "#9199B1" }}
                          >
                            perform action
                          </Button>
                          <Popover
                            id="mouse-over-popover"
                            open={currentPopover.id === row.id}
                            anchorEl={currentPopover.anchorEl}
                            onClose={() => handlePopoverClose(row.id)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Box
                              sx={{
                                width: "40%",
                                padding: "20px 40px",
                                cursor: "pointer",
                              }}
                            >
                              <Typography
                                sx={{ mb: 1 }}
                                onClick={() => {
                                  setOpenDeleteMOdal(true);
                                  setBeneficiaryToDelete(row.id);
                                  handlePopoverClose(row.id);
                                }}
                              >
                                Delete
                              </Typography>
                            </Box>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <DeleteficiaryModal
          OpenModal={openDeleteMOdal}
          handleClose={() => setOpenDeleteMOdal(false)}
          modalTitle="Do you want to remove this Beneficiary?"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              sx={{ marginRight: "20px", color: "secondary" }}
              onClick={() => {
                deleteBeneficiary(beneficiaryToDelete);
              }}
            >
               Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenDeleteMOdal(false);
              }}
            > No </Button>
          </Box>
        </DeleteficiaryModal>
        <CreateOrEditBeneficiaryModal
          OpenModal={showbeneficiaryForm}
          handleClose={() => setShowBeneficiaryForm(false)}
          modalTitle="Fill in the Beneficiary's details"
        >
          <BeneficiaryForm
            mode={beneficiaryFormState}
            successCb={() => setShowBeneficiaryForm(false)}
          />
        </CreateOrEditBeneficiaryModal>
      </Box>
    </>
  );
};
