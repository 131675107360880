import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormControl from "./form/FormControl";
import customFetch from "../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function ReassignTerminalForm({ tid, assigned, handleClose }) {
  const platformID = JSON.parse(localStorage.getItem("selectedPlatform"))?.id;

  const businessBranch = useSelector(
    (store) => store.businessBranch.businessBranch
  );

  const [branches, setBranches] = useState([]);

  const initialValues = {
    branch: "",
    terminal_id: "",
    wallet_id: "",
  };
  const validationSchema = Yup.object({
    branch: Yup.string().required("This field is required"),
  });

  const getBranches = async () => {
    try {
      const mapped = businessBranch
        ?.filter(
          (item) =>
            item.account_name !==
            assigned.replace(/Merchant|testaccount|\)|\(/gi, "")
        )
        .map((item) => {
          return {
            key: item.account_name,
            value: item.account_name,
            ...item,
          };
        });
      setBranches(mapped);
    } catch (error) {}
  };
  useEffect(() => {
    getBranches();
    // eslint-disable-next-line
  }, []);

  const url = `/dvault/__pos_terminal_reassign/?platform_id=${platformID}`;

  const onSubmit = async (values, onSubmitProps) => {
    try {
      const response = await customFetch.post(url, values);
      onSubmitProps.resetForm();
      handleClose();
      toast.success("Terminal reassigned successful");
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { isValid } = formik;
        return (
          <Form>
            <Box
              sx={{
                width: "400px",
              }}
            >
              <Box
                component={FormControl}
                control="select"
                name="branch"
                label="Select beneficiary"
                options={branches}
                value={formik.values.branch}
                onChange={(e) => {
                  formik.handleChange("branch")(e);
                  const selectedBranch = branches.find(
                    (branch) => branch.value === e.target.value
                  );
                  if (selectedBranch) {
                    formik.setValues({
                      ...formik.values,
                      branch: e.target.value,
                      terminal_id: (formik.values.terminal_id = tid),
                      wallet_id: selectedBranch.id,
                    });
                  } else {
                    formik.setValues({
                      ...formik.values,
                      branch: "",
                      terminal_id: "",
                      wallet_id: "",
                    });
                  }
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alightItem: "right",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!isValid || formik.isSubmitting}
                  // fullWidth
                  sx={{
                    "&.MuiButton-root": {
                      borderRadius: "7px",
                      my: 3,
                      textTransform: "capitalize",
                    },
                  }}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={35} color="secondary" />
                  ) : (
                    "Reassign terminal"
                  )}
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ReassignTerminalForm;
