import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormControl from "../form/FormControl";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addBeneficiary } from "../../features/beneficiary/beneficierySlice";

export const BeneficiaryForm = ({ mode, defaultValues, successCb }) => {
  const dispatch = useDispatch();
  const bankList = useSelector((state) => state.bankList?.bankList);
  const platformWalletID = JSON.parse(
    localStorage.getItem("selectedPlatform")
  )?.wallet;
  const initialValues = {
    account_number: "",
    bank_code: "",
  };
  const validationSchema = Yup.object({
    account_number: Yup.string().required("This field is required"),
    bank_code: Yup.string().required("This field is required"),
  });

  const buttonText =
    mode === "create" ? "Add beneficiary" : "update Beneficiary";

  const onSubmit = async (values, onSubmitProps) => {
    try {
      if (!values.account_number || !values.bank_code) {
        toast.error("Please fill in all the required fields");
        return;
      }

      dispatch(addBeneficiary({ platformWalletID, beneficiaryData: values }));
      successCb();

      onSubmitProps.resetForm();
    } catch (error) {}
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { isValid, isSubmitting } = formik;
        return (
          <Form>
            <Box
              sx={{
                width: "400px",
              }}
            >
              <Box
                component={FormControl}
                control="textInput"
                name="account_number"
                label="Account Number"
                required="*"
                value={formik.values.account_number.replace(/[^0-9]/gi, "")}
                onChange={formik.handleChange}
              />

              <Box
                component={FormControl}
                control="autoComplete"
                name="bank_code"
                label="Bank Name"
                options={bankList}
                onChange={(e, newValue) => {
            
                  formik.handleChange("bank_code")(e);
                  formik.setFieldValue("bank_code", newValue?.value);
                }}
              />

              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  sx={{
                    "&.MuiButton-root": {
                      borderRadius: "7px",
                      my: 3,
                      color: "#ffffff",
                      textTransform: "capitalize",
                    },
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    buttonText
                  )}
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
