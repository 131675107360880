import React, { useState } from "react";
import { Backdrop, CircularProgress,Grid } from "@mui/material";
import { PlatformCard } from "../components/IndicatorCard";
import { useDispatch, useSelector } from "react-redux";
import {setSelectedPlatform } from "../features/platform/platformSlice";
import { useNavigate } from "react-router-dom";

export const Platforms = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const platforms = useSelector((state) => state.platform?.platform);
  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zindex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
   
      <Grid
          container
          justifyContent={"space-between"}
          columnSpacing="50px"
          sx={{
            marginTop: "2rem",
          }}
        >
      {
          platforms?.map(platform => {
          
      return   <Grid key={platform.id} item md={6} lg={6} cursor="pointer" onClick={() => {     
        dispatch(setSelectedPlatform(platform))
        navigate("/dashboard")
        }}>
        <PlatformCard platform={platform}  />
          </Grid>
        }) 
        }
          </Grid>
    </>
  );
};


export default Platforms;
