import React from "react";
import { FieldArray, ErrorMessage } from "formik";
import { Box, Typography, MenuItem, TextField } from "@mui/material";
import { TextError } from "./TextError";
import Autocomplete from "@mui/material/Autocomplete";

const AutocompleteComponent = ({ label, name, options, value, ...rest }) => {
  return (
    <Box>
      <Typography
        component="label"
        htmlFor={name}
        sx={{ fontSize: "1rem", fontWeight: 500, color: "#454E68" }}
      >
        {label}
      </Typography>
      <FieldArray name={name}>
        {({ field }) => (
          <Autocomplete
            {...field}
            {...rest}
            options={[{ key: "Select...", value: "" }, ...options]}
            getOptionLabel={(option) => option.key}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
             
                style={{
               
                  outline: "none",
                  borderColor: " #c4c4c4",
                  margin: "0.5rem 0 1.5rem 0",
                }}
              />
            )}
            renderOption={(props, option) => (
              <MenuItem
                key={option.key}
                value={option.value}
                disabled={option.value === ""}
                sx={{ backgroundColor: "#f9f9f9" }}
                {...props}
              >
                {option.key}
              </MenuItem>
            )}
          />
        )}
      </FieldArray>
      <ErrorMessage component={TextError} name={name} />
    </Box>
  );
};

export default AutocompleteComponent;
