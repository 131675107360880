import React, { useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { BeneficiaryModal as Confirmation } from "../Beneficiaries/BeneficiaryModal";

const ConfirmationModal = ({modalTitle,openConfirmationModal,setOpenConfirmationModal,getConfirmation,setGetConfirmation,runFunc}) => {

  return (
    <Confirmation
      OpenModal={openConfirmationModal}
      handleClose={() => setOpenConfirmationModal(false)}
      modalTitle={modalTitle}

    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{ marginRight: "20px", color: "secondary" }}
          onClick={() => {
    
            runFunc()
            setGetConfirmation(true);
          }}
        >
          {getConfirmation ? <CircularProgress size={5} /> : "Yes"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setOpenConfirmationModal(false);
            setGetConfirmation(true);
          }}
        >
          {getConfirmation ? <CircularProgress /> : "No"}
        </Button>
      </Box>
    </Confirmation>
  );
};

export default ConfirmationModal;
