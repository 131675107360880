import React from "react";
import Input from "./Input";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import AutocompleteComponent from "./AutocompleteComponent";

const FormControl = ({ control, ...rest }) => {
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textInput":
      return <InputComponent {...rest} />;
    case "select":
      return <SelectComponent {...rest} />;
    case "autoComplete":
      return <AutocompleteComponent {...rest} />;
    default:
      return null;
  }
};

export default FormControl;
