import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { logoutUser } from "../features/dvaultUserSlice";

function ProtectedRoutes({ children }) {
  const { dvaultUser } = useSelector((store) => store.dvaultUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dvaultUser) {
      dispatch(logoutUser());
    }
  }, [dvaultUser, dispatch]);

  if (!dvaultUser) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default ProtectedRoutes;


