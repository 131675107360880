import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { Box, TextField, Typography } from "@mui/material";

import { TextError } from "./TextError";

function InputComponent(props) {
  const { label, name, helperText, required, maxLength, ...rest } = props;

  return (
    <Box sx={{ marginBottom: "2rem", fontFamily: "Poppins" }}>
      <Typography
        variant="label"
        component="label"
        htmlFor={name}
        sx={{ fontWeight: 500, color: "#454E68" }}
      >
        {label}
      </Typography>
      <br />

      <Field
        id={name}
        name={name}
        maxLength={maxLength}
        {...rest}
        as={TextField}
        sx={{
          width: "100%",
          margin: "0.5rem 0 0 0",
          "& .MuiInputBase-input": {
            padding: "9px 13px",
            border: "1px solid #E1E3E7",
          },
          "& .-MuiOutlinedInput": {
            borderRadius: "6px",
          },
          "& .-MuiOutlinedInput:focus": {
            outline: 0,
          },
        }}
      />
      <ErrorMessage component={TextError} name={name} />
    </Box>
  );
}

export default InputComponent;
