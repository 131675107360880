import { createTheme } from '@mui/material/styles';

const dvaultRed = '#E97D80';
const dvaultGreen = '#A5D6A7';
const dvaultGreenIcon = '#1B5E20';
const dvaultPurple = '#FFD8E4';
const dvaultPurpleIcon = '#31111D';
const dvaultFlagRed = '#FFCCBC';
const dvaultFlagIcon = '#BF360C';
const dvaultBlack = '#000000';
const dvaultWhite = '#ffffff';
const dvaultLinkRedBg = '#FFEDED';
const dvaultDarkBlue = '#032443';
const dvaultLightBlue = '#8CA1B6';

export const theme = createTheme({
  palette: {
    common: {
      red: `${dvaultRed}`,
      green: `${dvaultGreen}`,
      purple: `${dvaultPurple}`,
      purpleIcon: `${dvaultPurpleIcon}`,
      greenIcon: `${dvaultGreenIcon}`,
      flagRed: `${dvaultFlagRed}`,
      flagIcon: `${dvaultFlagIcon}`,
      linkRedBg: `${dvaultLinkRedBg}`,
      darkBlue: `${dvaultDarkBlue}`,
      lightBlue: `${dvaultLightBlue}`,
      black: `${dvaultBlack}`,
      white: `${dvaultWhite}`,
    },
    primary: {
      main: `${dvaultRed}`,
    },
    secondary: {
      main: `${dvaultBlack}`,
    },
    accent: {
      main: `${dvaultLightBlue}`,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
});
