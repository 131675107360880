import React, {  useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogBox(props) {
  const [open, setOpen] = useState(false);
  const variant = props.variant || "contained";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant={variant}
        color={props.buttonColor}
        onClick={handleOpen}
        sx={{
          fontSize: { md: "0.6rem", lg: "0.8rem" },
          backgroundColor: "secondary",
          width: "max-content",
        }}
      >
        {props.buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ borderRadius: "17.2494px" }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "500", fontSize: "1.2rem" }}>
            {props.title}
          </Typography>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {typeof props.children === "function" &&
            props.children({ handleClose: handleClose })}
        </DialogContent>
      </Dialog>
    </>
  );
}
