import React, { useState } from "react";
import { BusinessWalletTable } from "../components/BusinessWalletTable";
import { Backdrop, CircularProgress } from "@mui/material";

export const BusinessWallet = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zindex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <BusinessWalletTable isLoading={isLoading} setIsLoading={setIsLoading} />
    </>
  );
};
