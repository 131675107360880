import React from "react";
import Indicators from "./Indicators";
import {  Typography, Box } from "@mui/material";
import { BusinessWalletTable } from "../components/BusinessWalletTable";
import { useSelector } from "react-redux";

function Dashboard() {
  const isLoading = useSelector((store) => store.businessBranch);


  return (
     <Box sx={{ marginTop: "2.5rem" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "1.5rem" }}>
          Branch List
        </Typography>
        <Indicators />

        <BusinessWalletTable
          isLoading={isLoading}
        />
      </Box> 


  );
}

export default Dashboard;
