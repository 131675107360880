import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";

const initialState = {
  bankList: [],
  isLoading: true,
};

export const getBankList = createAsyncThunk(
  "bankList/getBankList",
  async (name, thunkAPI) => {
    try {
      const url = "/dvault/get_banks";
      const response = await customFetch.get(url);
      const res = response.data?.banks;

      const uniqueBankNames = new Set();
      const updatedBanks = res?.reduce((accumulator, item) => {
        if (!uniqueBankNames.has(item?.bankName)) {
          uniqueBankNames.add(item?.bankName);
          accumulator.push({ key: item?.bankName, value: String(item?.bankCode) });
        }
        return accumulator;
      }, []);
      return updatedBanks;
    } catch (error) {
      thunkAPI.rejectWithValue("Ooopppsss! Something went wrong");
    }
  }
);

const bankListSlice = createSlice({
  name: "bankList",
  initialState,
  reducers: {
    setLocalSearch: (state, action) => {
      state.localSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBankList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBankList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bankList = action.payload;
    });
    builder.addCase(getBankList.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setLocalSearch } = bankListSlice.actions;
export default bankListSlice.reducer;
