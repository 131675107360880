import { format, parseISO, parse } from 'date-fns';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertCodeToState(stateCode) {
  let state_of_origin = [
    {
      name: 'Abia',
      capital: 'Umuahia',
      code: 'AB',
    },
    {
      name: 'Adamawa',
      capital: 'Yola',
      code: 'AD',
    },
    {
      name: 'Akwa Ibom',
      capital: 'Uyo',
      code: 'AK',
    },
    {
      name: 'Anambra',
      capital: 'Awka',
      code: 'AN',
    },
    {
      name: 'Bauchi',
      capital: 'Bauchi',
      code: 'BA',
    },
    {
      name: 'Benue',
      capital: 'Makurdi',
      code: 'BE',
    },
    {
      name: 'Borno',
      capital: 'Maiduguri',
      code: 'BO',
    },
    {
      name: 'Bayelsa',
      capital: 'Yenagoa',
      code: 'BY',
    },
    {
      name: 'Cross River',
      capital: 'Calabar',
      code: 'CR',
    },
    {
      name: 'Delta',
      capital: 'Asaba',
      code: 'DE',
    },
    {
      name: 'Ebonyi',
      capital: 'Abakaliki',
      code: 'EB',
    },
    {
      name: 'Edo',
      capital: 'Benin',
      code: 'ED',
    },
    {
      name: 'Ekiti',
      capital: 'Ado-Ekiti',
      code: 'EK',
    },
    {
      name: 'Enugu',
      capital: 'Enugu',
      code: 'EN',
    },
    {
      name: 'Federal Capital Territory',
      capital: 'Abuja',
      code: 'FC',
    },
    {
      name: 'Gombe',
      capital: 'Gombe',
      code: 'GO',
    },
    {
      name: 'Jigawa',
      capital: 'Dutse',
      code: 'JI',
    },
    {
      name: 'Imo',
      capital: 'Owerri',
      code: 'IM',
    },
    {
      name: 'Kaduna',
      capital: 'Kaduna',
      code: 'KD',
    },
    {
      name: 'Kebbi',
      capital: 'Birnin Kebbi',
      code: 'KE',
    },
    {
      name: 'Kano',
      capital: 'Kano',
      code: 'KN',
    },
    {
      name: 'Kogi',
      capital: 'Lokoja',
      code: 'KO',
    },
    {
      name: 'Lagos',
      capital: 'Ikeja',
      code: 'LA',
    },
    {
      name: 'Katsina',
      capital: 'Katsina',
      code: 'KT',
    },
    {
      name: 'Kwara',
      capital: 'Ilorin',
      code: 'KW',
    },
    {
      name: 'Nasarawa',
      capital: 'Lafia',
      code: 'NA',
    },
    {
      name: 'Niger',
      capital: 'Minna',
      code: 'NI',
    },
    {
      name: 'Ogun',
      capital: 'Abeokuta',
      code: 'OG',
    },
    {
      name: 'Ondo',
      capital: 'Akure',
      code: 'ON',
    },
    {
      name: 'Rivers',
      capital: 'Port Harcourt',
      code: 'RI',
    },
    {
      name: 'Oyo',
      capital: 'Ibadan',
      code: 'YO',
    },
    {
      name: 'Osun',
      capital: 'Osogbo',
      code: 'OS',
    },
    {
      name: 'Sokoto',
      capital: 'Sokoto',
      code: 'SO',
    },
    {
      name: 'Plateau',
      capital: 'Jos',
      code: 'PL',
    },
    {
      name: 'Taraba',
      capital: 'Jalingo',
      code: 'TA',
    },
    {
      name: 'Yobe',
      capital: 'Damaturu',
      code: 'YO',
    },
    {
      name: 'Zamfara',
      capital: 'Gusau',
      code: 'ZA',
    },
  ];
  const newState = state_of_origin.filter((state) => {
    const { name, code } = state;

    return code === stateCode;
  });
  let convertedName = newState[0]?.name;

  return convertedName;
}

export function dateConverter(dateInput) {
  const dateObject = parseISO(dateInput);
  const formattedDate = format(dateObject, 'dd-MM-yyyy');
  return formattedDate;
}

export function dateConverter2(dateInput) {
  if (dateInput) {
  
    const dateString = dateInput.toDateString();
    const dateObj = parse(dateString, 'EEE MMM dd yyyy', new Date());
    const formattedDate = format(dateObj, 'yyyy-MM-dd');

    return formattedDate;
  }
}
dateConverter2(new Date());

export function timeConverter(timeInput) {
  const date = parseISO(timeInput);
  const localTime = format(date, 'HH:mm:ss a');
  return localTime;
}

export const currencyConverter = (amount) =>
new Intl.NumberFormat("en-NG", {
  style: "currency",
  currency: "NGN",
  maximumSignificantDigits: 6,
}).format(amount);